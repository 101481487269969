import React from "react";
import "./styles/RestaurantAppBenefits.css";

const RestaurantAppBenefits = () => {
  return (
    <div className="restaurant-app-benefits">
      <h2>Learn How Mobile Apps Can Benefit Your Restaurant Business</h2>
      <div className="benefit-list">
        <div className="benefit-item">
          <a
            href="/Key-to-Increasing-Restaurant-Profits"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img
              src={require("../../Images/Pricing/app-profits.webp")}
              alt="Restaurant Technology News"
              className="benefit-image"
            />
            <h3>
              Why Mobile Apps Hold the Key to Increasing Restaurant Profits
            </h3>
          </a>
          <p>
            Explore how a dedicated restaurant app can boost your profits by
            offering direct communication with customers, improving personalized
            experiences, and utilizing valuable customer data.
          </p>
        </div>
        <div className="benefit-item">
          <a
            href="/Transformed-the-Restaurant-and-Food-Delivery-Industry"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img
              src={require("../../Images/Pricing/delivery-app.webp")}
              alt="BBVA OpenMind"
              className="benefit-image"
            />
            <h3>
              How Mobile Apps Have Transformed the Restaurant and Food Delivery
              Industry
            </h3>
          </a>
          <p>
            Discover the impact of mobile apps on the restaurant industry,
            including enhanced efficiency in online ordering, table
            reservations, and customer feedback.
          </p>
        </div>
        <div className="benefit-item">
          <a
            href="/Benefits-and-Real-Life-Cases"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img
              src={require("../../Images/Pricing/benefits.webp")}
              alt="Celadon Soft"
              className="benefit-image"
            />
            <h3>
              Why Develop a Mobile App for Restaurants: Benefits and Real-Life
              Cases
            </h3>
          </a>
          <p>
            Learn how mobile apps can streamline restaurant management, enhance
            customer service, and boost sales through digital transformation.
          </p>
        </div>
        <div className="benefit-item">
          <a
            href="/25-Benefits-of-Having-a-Mobile-Application-for-a-Restaurant"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            <img
              src={require("../../Images/Pricing/real-time-case.webp")}
              alt="IIH Global"
              className="benefit-image"
            />
            <h3>25 Benefits of Having a Mobile Application for a Restaurant</h3>
          </a>
          <p>
            Find out how having a mobile app can revolutionize your restaurant’s
            operations, from better data security to increased customer
            retention.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RestaurantAppBenefits;
