import React, { useState, useEffect } from "react";
import "./styles/features.css";
import Navigation from "../../Utils/Navigation/Navigation";
import ScrollToTop from "../../Utils/ScrollToTop/ScrollToTop";
import Loading from "../../Loading";
import { imageList } from "./imageList";
function Features() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadedImages = 0;

    const loadImage = (url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageList.length) {
          setIsLoading(false);
        }
      };
    };

    imageList.forEach(loadImage);
  }, []);

  if (isLoading) {
    return (
      <>
        <Navigation />
        <Loading />
      </>
    );
  }
  return (
    <div className="features-container">
      <Navigation />
      <ScrollToTop />
      <div className="productFeatures">
        <div className="productFeaturesDetails">
          <h1 className="productFeatureTitle">Product Features</h1>
          <div className="BusinessProcess">
            <img
              src={require("../../Images/Features/business-progress.webp")}
              alt="pipes Analytic Details"
              className="BusinessProcessImg"
            />

            <div className="quickBoxDescriptions quickBoxSpecialStyle">
              <h3>The Benefits of a Comprehensive Restaurant App</h3>
              <p>
                Having a comprehensive app for your restaurant offers numerous
                benefits that can greatly enhance both customer experience and
                operational efficiency. For customers, it provides a convenient
                platform to access all services, from placing orders to
                receiving special offers, which helps in building loyalty and
                repeat business. The ability to manage personal information and
                track orders in real-time also empowers customers, making their
                experience more personalized and satisfying. For the restaurant
                management, the app streamlines operations by automating routine
                tasks, optimizing menu management, and providing valuable
                insights through detailed analytics. This not only saves time
                and reduces errors but also enables better decision-making to
                boost sales and improve customer service. Overall, a
                well-designed app can be a powerful tool to drive growth,
                increase customer satisfaction, and enhance overall restaurant
                management.
              </p>
            </div>
          </div>
          <div className="PFpartUser">
            <div className="PFquickBox">
              <img
                src={require("../../Images/Features/restaurant-dtl.webp")}
                alt="Restaurant’s Details"
              />
              <div className="quickBoxDescriptions">
                <h3>Essential Restaurant Details</h3>
                <p>
                  Empower your customers with easy access to your restaurant’s
                  key information, including operating hours, contact details,
                  and location. Make it effortless for them to choose you for
                  their next meal.
                </p>
              </div>
            </div>
            <div className="PFquickBox">
              <img
                src={require("../../Images/Features/sp-offer.webp")}
                alt="Restaurant’s Details"
              />
              <div className="quickBoxDescriptions">
                <h3>Special Offers & Order History</h3>
                <p>
                  Boost customer loyalty by offering exclusive deals and making
                  it simple for them to reorder their favorite dishes. Keep them
                  coming back with personalized offers that enhance their dining
                  experience.
                </p>
              </div>
            </div>
            <div className="PFquickBox">
              <img
                src={require("../../Images/Features/pr-details.webp")}
                alt="Restaurant’s Details"
              />
              <div className="quickBoxDescriptions">
                <h3>Simple Account Management</h3>
                <p>
                  Give your customers full control over their personal details,
                  allowing them to update their information or even delete their
                  account if needed. Build trust by putting their data security
                  in their hands.
                </p>
              </div>
            </div>
            <div className="PFquickBox">
              <img
                src={require("../../Images/Features/order.webp")}
                alt="Restaurant’s Details"
              />
              <div className="quickBoxDescriptions">
                <h3>Order Placement & Tracking</h3>
                <p>
                  Ensure a smooth and satisfying experience by allowing
                  customers to place orders effortlessly and track them in
                  real-time. Keep them informed and satisfied from order to
                  delivery.
                </p>
              </div>
            </div>
          </div>

          <div className="ProductAnalytic">
            <div className="pipesAnalytic">
              <img
                src={require("../../Images/Features/best-product.webp")}
                alt="pipes Analytic Details"
              />
              <img
                src={require("../../Images/Features/best-product-2.webp")}
                alt="pipes Analytic Details"
              />
            </div>

            <div className="quickBoxDescriptions quickBoxSpecialStyle">
              <h3>The Importance of Knowing Your Product Sales</h3>
              <p>
                Understanding your product sales is crucial for any restaurant.
                It helps you identify which dishes are popular and which ones
                aren't, allowing you to adjust your menu to better meet customer
                preferences. Tracking sales also enables you to manage inventory
                more efficiently, reducing waste and maximizing profits.
                Additionally, having a clear picture of your sales trends can
                help you create targeted promotions and specials to boost
                revenue during slower periods. Overall, knowing your sales data
                allows you to make informed decisions that can drive growth and
                enhance customer satisfaction.
              </p>
            </div>
          </div>
          <div className="PFpartUser">
            <div className="userManagement">
              <div className="UMsides">
                <div className="UMboxDesc">
                  <img
                    src={require("../../Images/Features/verified.webp")}
                    alt="verified user"
                  />
                  <p>Check verified users</p>
                </div>
                <div className="UMboxDesc">
                  <img
                    src={require("../../Images/Features/suspended.webp")}
                    alt="suspend user"
                  />
                  <p>Suspend users</p>
                </div>
                <div className="UMboxDesc">
                  <img
                    src={require("../../Images/Features/delete.webp")}
                    alt="delete user"
                  />
                  <p>Delete users</p>
                </div>
              </div>
              <div className="UMmiddle">
                <h2 className="UMmiddleTitle">Users Management</h2>
                <img
                  src={require("../../Images/Features/user-management.webp")}
                  alt="user management"
                  className="userManagementImg"
                />
              </div>
              <div className="UMsides">
                <div className="UMboxDesc">
                  <img
                    src={require("../../Images/Features/email-user.webp")}
                    alt="email marketing"
                  />
                  <p>Email marketing</p>
                </div>
                <div className="UMboxDesc">
                  <img
                    src={require("../../Images/Features/find-best-user.webp")}
                    alt="best user"
                  />
                  <p>Best users</p>
                </div>
                <div className="UMboxDesc">
                  <img
                    src={require("../../Images/Features/search-user.webp")}
                    alt="search user"
                  />
                  <p>Search users</p>
                </div>
              </div>
            </div>
            <div className="PFAdmin">
              <div className="PFquickBox addMarginBottom10">
                <img
                  src={require("../../Images/Features/orders-management.webp")}
                  alt="Restaurant’s Details"
                />
                <div className="quickBoxDescriptions">
                  <h3>Streamlined Order Management</h3>
                  <p>
                    Efficiently approve and manage orders with automated decline
                    features, ensuring that no unconfirmed orders slip through
                    the cracks. Regular checks for unapproved orders guarantee a
                    smooth operation.
                  </p>
                </div>
              </div>
              <div className="PFquickBox addMarginBottom10">
                <img
                  src={require("../../Images/Features/order-control.webp")}
                  alt="Restaurant’s Details"
                />
                <div className="quickBoxDescriptions">
                  <h3>Comprehensive Menu Control</h3>
                  <p>
                    Easily update and manage your menu, including item details,
                    prices, and availability. Add or remove items as needed to
                    keep your offerings fresh and aligned with customer demand.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="BusinessProcess extraMarginTop">
            <img
              src={require("../../Images/Features/support-options.webp")}
              alt="Support Details"
              className="AdditionalBoxesImg"
            />
            <div className="quickBoxDescriptions quickBoxSpecialStyle">
              <h3>The Advantages of Reliable Business Support Services</h3>
              <p>
                Offering reliable support services for your business brings
                numerous advantages that can significantly enhance both
                operational efficiency and customer satisfaction. With our
                flexible support options, ranging from a standard 48-hour
                response time to an expedited 2-hour response window, we ensure
                that your business can quickly adapt to any challenges that
                arise. Our dedicated support team is always ready to assist,
                providing timely solutions that help maintain smooth operations
                and minimize downtime. This rapid response capability not only
                improves customer satisfaction but also fosters trust and
                reliability, essential for building long-term business
                relationships.
              </p>
            </div>
          </div>
          <div className="BusinessProcess">
            <img
              src={require("../../Images/Features/updates.webp")}
              alt="updates Details"
              className="AdditionalBoxesImg"
            />
            <div className="quickBoxDescriptions quickBoxSpecialStyle">
              <h3>Stay Ahead with Monthly Updates and Feature Rollouts</h3>
              <p>
                Keep your app up-to-date and ahead of the competition with our
                regular monthly updates and feature rollouts. We continually
                improve the app's functionality and add new features based on
                user feedback and market trends, ensuring your restaurant always
                benefits from the latest innovations. These updates are designed
                to enhance user experience, boost engagement, and provide new
                opportunities for revenue growth, keeping your business agile
                and responsive to customer needs.
              </p>
            </div>
          </div>
          <div className="BusinessProcess">
            <img
              src={require("../../Images/Features/customization.webp")}
              alt="updates Details"
              className="AdditionalBoxesImg"
            />
            <div className="quickBoxDescriptions quickBoxSpecialStyle">
              <h3>Tailor Your App with Customization Options</h3>
              <p>
                Make your app uniquely yours with our extensive customization
                options. Whether it's modifying the interface to better align
                with your brand or adding specific features that cater to your
                customers' preferences, we offer flexible solutions that fit
                your restaurant's needs. These customization options empower you
                to create a more engaging and personalized experience for your
                users, enhancing customer satisfaction and loyalty.
              </p>
            </div>
          </div>
          <div className="BusinessProcess">
            <img
              src={require("../../Images/Features/training.webp")}
              alt="Training Details"
              className="AdditionalBoxesImg"
            />
            <div className="quickBoxDescriptions quickBoxSpecialStyle">
              <h3>Expert Setup and Training Services</h3>
              <p>
                Ensure a smooth transition to using your new app with our
                professional setup and training services. For an additional fee,
                our team can provide onsite assistance to help set up the app
                and train your staff on how to use it effectively. This service
                guarantees that your team is fully equipped to leverage the
                app’s capabilities, reducing the learning curve and enhancing
                operational efficiency from day one.
              </p>
            </div>
          </div>
        </div>
        <div className="PFcontent">
          <section className="featuresContentUserAdmin">
            <h1 className="PFcontentTitles">Admin Features You Can Enable</h1>
            <ul className="main-list">
              <li className="main-list-item">Approve and manage orders</li>
              <li className="main-list-item">
                Automated option to decline orders if not confirmed within 5
                minutes
              </li>
              <li className="main-list-item">
                Server checks every 30 minutes for any faulty orders left
                without approval and automatically declines them
              </li>
              <li className="main-list-item">Update and manage the menu</li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Edit item names, descriptions, images, price, category, and
                  availability
                </li>
                <li className="nested-list-item">Add items to the menu</li>
                <li className="nested-list-item">Remove items from the menu</li>
              </ul>
              <li className="main-list-item">View all orders</li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Filter orders by status, date, and completion
                </li>
              </ul>
              <li className="main-list-item">View all users</li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Filter users by Verified status
                </li>
                <li className="nested-list-item">
                  Filter users by Suspended/Not-Suspended status
                </li>
                <li className="nested-list-item">
                  Filter users by Marketing Notifications Enabled/Disabled
                </li>
                <li className="nested-list-item">
                  Search users by email, name, or User ID
                </li>
              </ul>
              <li className="main-list-item">Manage user accounts</li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Suspend users, Un-suspend users, Issue special offers to
                  users, Delete user accounts
                </li>
                <li className="nested-list-item">
                  View detailed user analytics
                </li>
                <ul className="nested-sublist">
                  <li className="nested-sublist-item">
                    Graphical representations of order volume
                  </li>
                  <li className="nested-sublist-item">Expenditure over time</li>
                  <li className="nested-sublist-item">
                    Specific order details by selecting time periods
                  </li>
                </ul>
              </ul>
              <li className="main-list-item">
                Access detailed analytics for all orders
              </li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Graphical representations of order volume
                </li>
                <li className="nested-list-item">Expenditure over time</li>
                <li className="nested-list-item">
                  Specific order details by selecting time periods
                </li>
              </ul>
              <li className="main-list-item">Order users by spending amount</li>
              <li className="main-list-item">
                Order users by number of orders made
              </li>
              <li className="main-list-item">
                Order products by revenue earned
              </li>
              <li className="main-list-item">
                Order products by the number of times sold
              </li>
              <li className="main-list-item">
                View detailed analytics for products
              </li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Bar graphical representations for Total Earnings
                </li>
                <li className="nested-list-item">
                  Bar graphical representations for Total Amount Sold
                </li>
              </ul>
              <li className="main-list-item">
                View and manage sales data through charts
              </li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Highlight peak ordering times
                </li>
                <li className="nested-list-item">
                  Identify top-selling products
                </li>
                <li className="nested-list-item">
                  Rank users based on volume and revenue
                </li>
                <li className="nested-list-item">
                  Rank products based on volume and revenue
                </li>
              </ul>
              <li className="main-list-item">
                Create special offers with expiration dates
              </li>
              <li className="main-list-item">Manage special offers</li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Automatically remove expired special offers
                </li>
                <li className="nested-list-item">
                  Remove users from special offers once used
                </li>
                <li className="nested-list-item">
                  Auto-remove special offers if no users are left
                </li>
                <li className="nested-list-item">
                  Send notifications for each special offer
                </li>
              </ul>
              <li className="main-list-item">Send notifications at any time</li>
              <li className="main-list-item">View all special offers</li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Filter special offers by date (From and Until)
                </li>
                <li className="nested-list-item">
                  Filter by free item or discount offers
                </li>
                <li className="nested-list-item">
                  Sort by expiration date, discount price, free item price, or
                  most users going
                </li>
              </ul>
              <li className="main-list-item">Update restaurant information</li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Edit operating hours, contact details, delivery charges, Add
                  and update notes
                </li>
              </ul>
              <li className="main-list-item">Logout</li>
              <li className="main-list-item">
                Support for multiple roles with specific administrative controls
              </li>
              <ul className="nested-list">
                <li className="nested-list-item">Order management access</li>
                <li className="nested-list-item">Limited staff interaction</li>
                <li className="nested-list-item">
                  No access to user management or menu modifications for certain
                  roles
                </li>
              </ul>
            </ul>
          </section>

          <section className="featuresContentUserAdmin">
            <h1 className="PFcontentTitles">User Features You Can Enable</h1>
            <ul className="main-list">
              <li className="main-list-item">
                Access and redeem special offers tailored to personal
                preferences
              </li>
              <li className="main-list-item">
                Review detailed history of past orders
              </li>
              <ul className="nested-list">
                <li className="nested-list-item">View item specifics</li>
                <li className="nested-list-item">See order dates</li>
                <li className="nested-list-item">Check order totals</li>
              </ul>
              <li className="main-list-item">
                Edit personal profile information
              </li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Change name, Update password, Edit contact details
                </li>
              </ul>
              <li className="main-list-item">
                Option to securely delete account adhering to GDPR regulations
              </li>
              <li className="main-list-item">
                Place and track orders in real-time
              </li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Receive updates at each stage of processing
                </li>
              </ul>
              <li className="main-list-item">Explore restaurant details</li>
              <ul className="nested-list">
                <li className="nested-list-item">View menu</li>
                <li className="nested-list-item">Read reviews</li>
                <li className="nested-list-item">Check ratings</li>
              </ul>
              <li className="main-list-item">
                Receive real-time notifications
              </li>
              <ul className="nested-list">
                <li className="nested-list-item">Updates for order statuses</li>
                <li className="nested-list-item">
                  Notifications for newly available offers
                </li>
              </ul>
              <li className="main-list-item">Adjust notification settings</li>
              <ul className="nested-list">
                <li className="nested-list-item">
                  Receive information on preferred offers only
                </li>
              </ul>
              <li className="main-list-item">Use a loyalty rewards system</li>
              <ul className="nested-list">
                <li className="nested-list-item">Accrue points</li>
                <li className="nested-list-item">
                  Redeem points for discounts or free items
                </li>
              </ul>
              <li className="main-list-item">
                Feedback system to rate orders and provide comments for service
                improvement
              </li>
            </ul>
          </section>
        </div>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Features;
