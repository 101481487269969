// images.js
export const imageList = [
  require("../../Images/Features/best-product-2.webp"),
  require("../../Images/Features/best-product.webp"),
  require("../../Images/Features/business-progress.webp"),
  require("../../Images/Features/customization.webp"),
  require("../../Images/Features/delete.webp"),
  require("../../Images/Features/email-user.webp"),
  require("../../Images/Features/find-best-user.webp"),
  require("../../Images/Features/menu.webp"),
  require("../../Images/Features/notifications.webp"),
  require("../../Images/Features/order-control.webp"),
  require("../../Images/Features/order.webp"),
  require("../../Images/Features/orders-management.webp"),
  require("../../Images/Features/pr-details.webp"),
  require("../../Images/Features/restaurant-dtl.webp"),
  require("../../Images/Features/search-user.webp"),
  require("../../Images/Features/sp-offer.webp"),
  require("../../Images/Features/support-options.webp"),
  require("../../Images/Features/suspended.webp"),
  require("../../Images/Features/training.webp"),
  require("../../Images/Features/updates.webp"),
  require("../../Images/Features/user-management.webp"),
  require("../../Images/Features/verified.webp"),
  // ... add more images
];
