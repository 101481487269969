import React, { useEffect, useState } from "react";

const Support = ({ color = "#000000", width = "200px", height = "200px" }) => {
  const [SVGposition, setSVGposition] = useState({
    a: 0,
    b: -100,
    c: 755,
    d: 1000,
  });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 900) {
        setSVGposition({
          a: 0,
          b: -100,
          c: 755,
          d: 1000,
        });
      }
      if (window.innerWidth <= 900) {
        setSVGposition({ a: 0, b: -600, c: 755, d: 1700 });
      }
      if (window.innerWidth <= 1000) {
        // setSVGposition({ x: "150", y: "150" });
      }
    }

    // Set up the event listener
    window.addEventListener("resize", handleResize);

    // Initial check in case the window is already less than or equal to 100px
    handleResize();

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <svg
      enableBackground="new 0 0 771.001 772.819"
      version="1.1"
      viewBox={`${SVGposition.a} ${SVGposition.b} ${SVGposition.c} ${SVGposition.d}`}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m385.51 29.637c-197.04 0-356.77 159.73-356.77 356.77s159.73 356.77 356.77 356.77 356.77-159.73 356.77-356.77-159.73-356.77-356.77-356.77zm0 665.56c-170.54 0-308.79-138.25-308.79-308.79s138.25-308.79 308.79-308.79 308.78 138.25 308.78 308.79-138.25 308.79-308.78 308.79z"
        fill={color}
      />
      <g fill={color}>
        <path d="m263.99 333.63c-9.549-1.758-19.166-14.04-27.625-5.853-8.664 8.386-6.32 22.493-7.11 34.519-8.184 124.6 62.324 200.96 167.91 228.77 67.423 17.758 134.19-3.586 188.73-51.68 35.268-31.098 56.074-69.612 66.034-114.67 3.239-14.652 0.989-25.637-9.977-37.948-39.004-43.79-71.455-91.619-80.06-152-19.637 1.506-25.919 20.233-36.714 30.201-42.408 39.16-92.418 58.886-147.92 67.993-37.57 6.17-75.37 7.64-113.26 0.67z" />
        <path d="m316.53 608.35c-12.512-6.487-24.276-10.459-38.383-9.939-67.329 2.481-110.64-46.304-110.44-107.9 0.088-26.02 6.843-50.644 8.332-76.343 4.751-81.989 26.299-160.01 84.296-222.01 54.479-58.233 122.1-87.299 199.61-80.33-23.729-6.418-48.681-9.863-74.44-9.863-157.09 0-284.44 127.35-284.44 284.44s127.35 284.44 284.44 284.44c63.716 0 122.54-20.953 169.95-56.34-79.78 36.71-159.67 34.93-238.93-6.16z" />
      </g>
      <g fill={color}>
        <path d="m291.3 422.6c9.093-9.548 14.658-22.456 14.658-36.68 0-21.622-12.446-40.219-31.446-48.547v-5.247c0-13.346-9.961-17.859-22-17.859s-22 4.513-22 17.859v5.247c-20 8.329-31.194 26.925-31.194 48.547 0 29.392 23.94 53.219 53.333 53.219 24.99 0 38.65-16.54 38.65-16.54z" />
        <path d="m428.57 502.54c-6.469 0-12.345 3.284-15.704 8.276-36.573-24.841-82.36-50.671-120.36-95.234v0.765c-11 6.436-19 15.938-31 19.687v1.591c47 37.218 113.06 74.884 148.71 83.242-2e-3 0.127-0.186 0.25-0.186 0.378 0 10.331 8.291 18.705 18.622 18.705s18.663-8.375 18.663-18.705c0.01-10.34-8.41-18.72-18.74-18.72z" />
      </g>
    </svg>
  );
};

export default Support;
