import React, { useState, useEffect } from "react";
import Navigation from "../../Utils/Navigation/Navigation";
import "./styles/pricing.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RestaurantAppBenefits from "./RestaurantAppBenefits.js";
import FQAs from "./FQAs.js";
import ScrollToTop from "../../Utils/ScrollToTop/ScrollToTop.js";
import Footer from "../../Utils/Footer/Footer.js";

import Loading from "../../Loading";
import { imageList } from "./imageList";

function Pricing() {
  const location = useLocation();
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadedImages = 0;

    const loadImage = (url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageList.length) {
          setIsLoading(false);
        }
      };
    };

    imageList.forEach(loadImage);
  }, []);

  useEffect(() => {
    if (location.hash === "#faqs_element") {
      const element = document.getElementById("faqs_element");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  if (isLoading) {
    return (
      <>
        <Navigation />
        <Loading />
      </>
    );
  }

  return (
    <div className="Pricing">
      <Navigation />
      <ScrollToTop />
      <div className="PricingChoosing">
        <div className="lighting-effect"></div>
        <div className="PricingCallToAction">
          <h1>Transparent, Affordable Pricing</h1>
          <p>
            Get the apps you need with clear, upfront pricing and no hidden
            fees.
          </p>
          <Link to="/free-trial">Book a Free Trial</Link>
        </div>
      </div>
      <div className="PricingPackages">
        <h2 className="PPtitle">Choose the solutions you need</h2>
        <p className="PPparagraph">
          We offer a 14-day satisfaction guarantee—no charges if you're not
          satisfied. You can also book a meeting to find the best app solutions
          for your business needs.
        </p>
        <div className="PPdetails">
          <div className="package">
            <h2>Basic Tier (£1,500 per year)</h2>
            <div className="features">
              <h3>Core Features:</h3>
              <ul>
                <li>User ability to view and avail special offers.</li>
                <li>Place and track orders.</li>
                <li>
                  Receive notifications related to offers and order statuses.
                </li>
              </ul>

              <h3>Admin Features:</h3>
              <ul>
                <li>Approve and manage orders.</li>
                <li>
                  Update and manage the menu (limited to basic features like
                  adding or removing items).
                </li>
              </ul>

              <h3>Support:</h3>
              <ul>
                <li>
                  Email support with a guaranteed response within 48 hours.
                </li>
              </ul>
            </div>
            <br />
            <div
              className="btn"
              onClick={() => {
                navigation("/schedule-demo#Basic-Tier");
              }}
            >
              Select Basic Tier
            </div>
          </div>

          <div className="package">
            <h2>Enterprise Tier (£5,000 per year)</h2>
            <div className="features">
              <h3>Core Features: All from Premium plus:</h3>
              <ul>
                <li>User ability to view and avail special offers.</li>
                <li>Place and track orders.</li>
                <li>
                  Receive notifications related to offers and order statuses.
                </li>
                <li>Review past orders and update personal details.</li>
                <li>Detailed restaurant information viewing.</li>
                <li>
                  Delete account and manage personal security settings for
                  users.
                </li>
                <li>
                  Receive and manage push notifications for offers and updates.
                </li>
              </ul>

              <h3>Public Restaurant Website:</h3>
              <ul>
                <li>
                  A professional, responsive 1-page website designed to showcase
                  your restaurant's branding and key information.
                </li>
                <li>
                  Optimized for SEO and mobile viewing, ensuring a seamless
                  experience across devices.
                </li>
                <li>
                  Branded with your logo, color scheme, and content tailored to
                  your restaurant's identity.
                </li>
              </ul>

              <h3>Admin Features: All from Premium plus:</h3>
              <ul>
                <li>Approve and manage orders.</li>
                <li>
                  Update and manage the menu (limited to basic features like
                  adding or removing items).
                </li>
                <li>
                  More comprehensive menu management (item descriptions, images,
                  pricing).
                </li>
                <li>View all orders and basic user filters.</li>
                <li>Basic analytics (e.g., order volume and expenditure).</li>
                <li>
                  Full user account management (suspend/unsuspend, delete).
                </li>
                <li>Advanced analytics with graphical representations.</li>
                <li>Special offers management and detailed filtering.</li>
                <li>
                  Automated decline of orders not confirmed within a specific
                  timeframe.
                </li>
              </ul>

              <h3>Support:</h3>
              <ul>
                <li>
                  Priority phone and email support with a response within 2
                  hours.
                </li>
                <li>Dedicated account manager.</li>
              </ul>

              <h3>Additional Services:</h3>
              <ul>
                <li>Monthly updates and feature rollouts.</li>
                <li>
                  Customization options for the app interface and features.
                </li>
              </ul>
              <br />
              <div
                className="btn"
                onClick={() => {
                  navigation("/schedule-demo#Enterprise-Tier");
                }}
              >
                Select Enterprise Tier
              </div>
            </div>
          </div>

          <div className="package">
            <h2>Premium Tier (£3,000 per year)</h2>
            <div className="features">
              <h3>Core Features: All from Basic plus:</h3>
              <ul>
                <li>User ability to view and avail special offers.</li>
                <li>Place and track orders.</li>
                <li>
                  Receive notifications related to offers and order statuses.
                </li>
                <li>Review past orders and update personal details.</li>
                <li>Detailed restaurant information viewing.</li>
              </ul>

              <h3>Admin Features: All from Basic plus:</h3>
              <ul>
                <li>Approve and manage orders.</li>
                <li>
                  Update and manage the menu (limited to basic features like
                  adding or removing items).
                </li>
                <li>
                  More comprehensive menu management (item descriptions, images,
                  pricing).
                </li>
                <li>View all orders and basic user filters.</li>
                <li>Basic analytics (e.g., order volume and expenditure).</li>
              </ul>

              <h3>Support:</h3>
              <ul>
                <li>
                  Phone and email support with a guaranteed response within 24
                  hours.
                </li>
              </ul>

              <h3>Additional Services:</h3>
              <ul>
                <li>Quarterly system updates.</li>
              </ul>
              <br />
              <div
                className="btn"
                onClick={() => {
                  navigation("/schedule-demo#Premium-Tier");
                }}
              >
                Select Premium Tier
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <RestaurantAppBenefits />
      <br />
      <div id="faqs_element">
        <FQAs />
      </div>
      <br />
      <Footer />
    </div>
  );
}

export default Pricing;
