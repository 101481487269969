import React, { useEffect, useState } from "react";

const Resources = ({
  color = "#000000",
  width = "200px",
  height = "200px",
}) => {
  const [SVGposition, setSVGposition] = useState({
    a: 0,
    b: -28,
    c: 160,
    d: 200,
  });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 900) {
        setSVGposition({
          a: 0,
          b: -28,
          c: 160,
          d: 200,
        });
      }
      if (window.innerWidth <= 900) {
        setSVGposition({ a: 70, b: -100, c: 1, d: 300 });
      }
      if (window.innerWidth <= 1000) {
        // setSVGposition({ x: "150", y: "150" });
      }
    }

    // Set up the event listener
    window.addEventListener("resize", handleResize);

    // Initial check in case the window is already less than or equal to 100px
    handleResize();

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 162.5 142.5"
      version="1.1"
      width={width}
      height={height}
      viewBox={`${SVGposition.a} ${SVGposition.b} ${SVGposition.c} ${SVGposition.d}`}
    >
      <style type="text/css">
        {`
        .st0 { display: none; }
        .st1 { display: inline; }
        .st2 { fill: ${color}; }
        .st3 { fill: ${color}; }
        .st4 { fill: ${color}; }
        .st5 { fill:${color}; }
        .st6 { fill: ${color}; }
        .st7 { fill: #BD982E; }
        .st8 { fill: #FEDD27; }
        .st9 { fill: #FFC92B; }
        .st10 { fill: ${color}; }
        .st11 { fill: ${color}; }
      `}
      </style>
      <g className="st0">
        <g className="st1"></g>
      </g>
      <polygon points="141.2 120.7 119.7 120.7 119.7 119.7 140.2 119.7 140.2 87.8 141.2 87.8" />
      <path
        className="st2"
        d="m35.2 62h-27.4c-0.8 0-1.4-0.6-1.4-1.4v-38.7c0-0.8 0.6-1.4 1.4-1.4h27.4c0.8 0 1.4 0.6 1.4 1.4v38.6c0 0.9-0.6 1.5-1.4 1.5z"
      />
      <rect className="st3" x="6.4" y="26.2" width="30.2" height="32.2" />
      <rect className="st4" x="6.4" y="52.2" width="30.2" height="6.2" />
      <rect className="st5" x="7.8" y="22" width="4.5" height="2.8" />
      <line className="st6" x1="15" x2="32.8" y1="23.4" y2="23.4" />
      <path d="m32.8 24.4h-17.8c-0.6 0-1-0.4-1-1s0.4-1 1-1h17.8c0.6 0 1 0.4 1 1s-0.5 1-1 1z" />
      <line className="st6" x1="14.8" x2="25.7" y1="34" y2="34" />
      <path d="m25.7 34.5h-10.9c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h10.9c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="15" x2="30.5" y1="39.8" y2="39.8" />
      <path d="m30.5 40.2h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="15" x2="30.5" y1="45.2" y2="45.2" />
      <path d="m30.5 45.8h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <path className="st7" d="m10.1 36.1v-2.5l-0.1 0.3 0.1 2.2z" />
      <path
        className="st8"
        d="m11 32.8c0-0.2-0.1-0.5-0.3-0.7-0.3-0.3-0.8-0.4-1.2-0.2-0.3 0.2-0.5 0.6-0.5 1 0 0.3 0.3 0.6 0.6 0.7v0.2l-0.1 0.2h0.4v0.5l-0.1 0.1 0.2 0.2v0.1l-0.2 0.2v0.1l0.2 0.2v0.1l-0.1 0.1v0.2l0.3 0.3c0.1 0 0.1-0.1 0.2-0.2v-1.9s0-0.1 0.1-0.1h0.2l-0.2-0.2v-0.2c0.2-0.1 0.4-0.3 0.5-0.5v-0.2zm-1.3-0.4c0-0.1 0.1-0.2 0.2-0.3s0.3 0 0.4 0.1 0.1 0.2 0.1 0.3-0.2 0.2-0.3 0.2c-0.2 0-0.4-0.1-0.4-0.3z"
      />
      <path
        className="st9"
        d="m10.1 36.3l-0.4-0.4v-0.3l0.1-0.2-0.2-0.2v-0.2l0.2-0.2-0.3-0.3 0.2-0.2v-0.2h-0.5l0.2-0.4c-0.3-0.2-0.6-0.5-0.6-0.8-0.1-0.4 0.2-0.9 0.6-1 0.4-0.2 1-0.1 1.3 0.2 0.2 0.2 0.3 0.5 0.3 0.7 0 0.1 0 0.2-0.1 0.4s-0.3 0.4-0.5 0.6l0.3 0.4h-0.4v1.9s0 0.1-0.2 0.2zm-0.2-0.5l0.2 0.2v-1.9c0-0.1 0.1-0.2 0.2-0.2 0 0-0.1-0.1 0-0.2 0-0.1 0.1-0.1 0.1-0.1 0.2-0.1 0.4-0.3 0.4-0.4s0.1-0.2 0.1-0.3c0-0.2-0.1-0.4-0.2-0.6-0.3-0.3-0.7-0.3-1.1-0.1-0.3 0.1-0.5 0.5-0.4 0.8 0 0.3 0.2 0.5 0.5 0.6 0.1 0 0.1 0.1 0.1 0.1v0.3h0.1c0.1 0 0.1 0 0.2 0.1l0.1 0.1v0.3c0 0.1 0 0.1-0.1 0.1l0.1 0.1c0.1 0.1 0.1 0.2 0 0.3l-0.2 0.2 0.2 0.2c0.1 0.1 0.1 0.2 0 0.3l-0.3 0.1zm0.1-2.9c-0.2 0-0.4-0.2-0.4-0.4v-0.1c0-0.2 0.1-0.3 0.2-0.4 0.2-0.1 0.4-0.1 0.5 0.1 0.1 0.1 0.2 0.3 0.1 0.5 0 0.1-0.2 0.3-0.4 0.3zm0-0.7c-0.2 0.1-0.2 0.1-0.2 0.2s0.1 0.2 0.2 0.2 0.2-0.1 0.2-0.1v-0.2c-0.1 0-0.1-0.1-0.2-0.1z"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 31.082 4.4601)"
        x="9.1"
        y="38.7"
        width="2"
        height="2"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 34.972 6.071)"
        x="9.1"
        y="44.2"
        width="2"
        height="2"
      />
      <path
        className="st6"
        d="m25.2 56.2h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z"
      />
      <polygon className="st11" points="32 54.2 32 57.3 34.9 55.7" />
      <path
        className="st2"
        d="m35.2 112.5h-27.4c-0.8 0-1.4-0.6-1.4-1.4v-38.7c0-0.8 0.6-1.4 1.4-1.4h27.4c0.8 0 1.4 0.6 1.4 1.4v38.6c0 0.9-0.6 1.5-1.4 1.5z"
      />
      <rect className="st3" x="6.4" y="76.8" width="30.2" height="32.2" />
      <rect className="st4" x="6.4" y="102.8" width="30.2" height="6.2" />
      <rect className="st5" x="7.8" y="72.5" width="4.5" height="2.8" />
      <line className="st6" x1="15" x2="32.8" y1="73.9" y2="73.9" />
      <path d="m32.8 74.9h-17.8c-0.6 0-1-0.4-1-1s0.4-1 1-1h17.8c0.6 0 1 0.4 1 1s-0.5 1-1 1z" />
      <line className="st6" x1="14.8" x2="25.7" y1="84.5" y2="84.5" />
      <path d="M25.7,85H14.8c-0.3,0-0.5-0.2-0.5-0.5s0.2-0.5,0.5-0.5h10.9c0.3,0,0.5,0.2,0.5,0.5S26,85,25.7,85z" />
      <line className="st6" x1="15" x2="30.5" y1="90.2" y2="90.2" />
      <path d="m30.5 90.8h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="15" x2="30.5" y1="95.8" y2="95.8" />
      <path d="m30.5 96.2h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <path className="st7" d="m10.1 86.6v-2.5l-0.1 0.3 0.1 2.2z" />
      <path
        className="st8"
        d="m11 83.3c0-0.2-0.1-0.5-0.3-0.7-0.3-0.3-0.8-0.4-1.2-0.2-0.3 0.2-0.5 0.6-0.5 1 0 0.3 0.3 0.6 0.6 0.7v0.2l-0.1 0.2h0.4v0.5h-0.2l0.2 0.2v0.1l-0.2 0.2v0.1l0.2 0.2v0.1l-0.1 0.1v0.2l0.3 0.3c0.1 0 0.1-0.1 0.2-0.2v-1.9s0-0.1 0.1-0.1h0.2l-0.2-0.2v-0.2c0.2-0.1 0.4-0.3 0.5-0.5 0.1 0.1 0.1 0 0.1-0.1zm-1.3-0.4c0-0.1 0.1-0.2 0.2-0.3s0.3 0 0.4 0.1 0.1 0.2 0.1 0.3-0.2 0.2-0.3 0.2c-0.2 0-0.4-0.1-0.4-0.3z"
      />
      <path
        className="st9"
        d="m10.1 86.8l-0.4-0.4v-0.3l0.1-0.1-0.2-0.2v-0.2l0.2-0.2-0.3-0.4 0.2-0.2v-0.2h-0.5l0.2-0.4c-0.3-0.2-0.6-0.5-0.6-0.8-0.1-0.4 0.2-0.9 0.6-1 0.4-0.2 1-0.1 1.3 0.2 0.2 0.2 0.3 0.5 0.3 0.7 0 0.1 0 0.2-0.1 0.4s-0.3 0.4-0.5 0.6l0.3 0.4h-0.4v1.9s0 0.1-0.2 0.2zm-0.2-0.5l0.2 0.2v-1.9c0-0.1 0.1-0.2 0.2-0.2 0 0-0.1-0.1 0-0.2 0-0.1 0.1-0.1 0.1-0.1 0.2-0.1 0.4-0.3 0.4-0.4s0.1-0.2 0.1-0.3c0-0.2-0.1-0.4-0.2-0.6-0.3-0.3-0.7-0.3-1.1-0.1-0.3 0.1-0.5 0.5-0.4 0.8 0 0.3 0.2 0.5 0.5 0.6 0.1 0 0.1 0.1 0.1 0.1v0.3h0.1c0.1 0 0.1 0 0.2 0.1l0.1 0.1v0.3c0 0.1 0 0.1-0.1 0.1l0.1 0.1c0.1 0.1 0.1 0.2 0 0.3l-0.2 0.2 0.2 0.2c0.1 0.1 0.1 0.2 0 0.3l-0.3 0.1zm0.1-2.9c-0.2 0-0.4-0.2-0.4-0.4v-0.1c0-0.2 0.1-0.3 0.2-0.4 0.2-0.1 0.4-0.1 0.5 0.1 0.1 0.1 0.2 0.3 0.1 0.5 0 0.1-0.2 0.3-0.4 0.3zm0-0.7c-0.2 0.1-0.2 0.1-0.2 0.2s0.1 0.2 0.2 0.2 0.2-0.1 0.2-0.1v-0.2c-0.1 0-0.1-0.1-0.2-0.1z"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 66.791 19.251)"
        x="9.1"
        y="89.2"
        width="2"
        height="2"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 70.68 20.862)"
        x="9.1"
        y="94.7"
        width="2"
        height="2"
      />
      <path
        className="st6"
        d="m25.2 106.7h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z"
      />
      <path
        className="st2"
        d="m81.4 89h-27.3c-0.8 0-1.4-0.6-1.4-1.4v-38.7c0-0.8 0.6-1.4 1.4-1.4h27.4c0.8 0 1.4 0.6 1.4 1.4v38.6c0 0.9-0.7 1.5-1.5 1.5z"
      />
      <rect className="st3" x="52.6" y="53.2" width="30.2" height="32.2" />
      <rect className="st4" x="52.6" y="79.2" width="30.2" height="6.2" />
      <rect className="st5" x="54" y="49" width="4.5" height="2.8" />
      <line className="st6" x1="61.2" x2="79" y1="50.4" y2="50.4" />
      <path d="m79 51.4h-17.8c-0.6 0-1-0.4-1-1s0.4-1 1-1h17.8c0.6 0 1 0.4 1 1s-0.4 1-1 1z" />
      <line className="st6" x1="61.1" x2="71.9" y1="61" y2="61" />
      <path d="m71.9 61.5h-10.8c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h10.9c0.3 0 0.5 0.2 0.5 0.5s-0.3 0.5-0.6 0.5z" />
      <line className="st6" x1="61.3" x2="76.8" y1="66.8" y2="66.8" />
      <path d="m76.8 67.2h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="61.3" x2="76.8" y1="72.2" y2="72.2" />
      <path d="m76.8 72.8h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <path className="st7" d="m56.4 63.1v-2.5l-0.1 0.3 0.1 2.2z" />
      <path
        className="st8"
        d="m57.2 59.8c0-0.2-0.1-0.5-0.3-0.7-0.3-0.3-0.8-0.4-1.2-0.2-0.3 0.2-0.5 0.6-0.5 0.9s0.3 0.6 0.6 0.7v0.5h0.4v0.5h-0.2l0.2 0.2v0.1l-0.2 0.3v0.1l0.2 0.2v0.1l-0.1 0.1v0.2l0.3 0.3c0.1 0 0.1-0.1 0.2-0.2v-1.9s0-0.1 0.1-0.1h0.2l-0.2-0.2v-0.2c0.2-0.1 0.4-0.3 0.5-0.5v-0.2zm-1.2-0.4c0-0.1 0.1-0.2 0.2-0.3s0.3 0 0.4 0.1 0.1 0.2 0.1 0.3-0.2 0.2-0.3 0.2c-0.3 0-0.4-0.1-0.4-0.3z"
      />
      <path
        className="st9"
        d="m56.4 63.3l-0.4-0.4v-0.3l0.1-0.2-0.2-0.2v-0.2l0.2-0.2-0.3-0.3 0.2-0.2v-0.2h-0.5l0.2-0.4c-0.3-0.2-0.6-0.5-0.6-0.8-0.1-0.4 0.2-0.9 0.6-1 0.4-0.2 1-0.1 1.3 0.2 0.2 0.2 0.3 0.5 0.3 0.7 0 0.1 0 0.2-0.1 0.4s-0.3 0.4-0.5 0.6l0.3 0.4h-0.4v1.9l-0.2 0.2zm-0.2-0.5l0.2 0.2v-1.9c0-0.1 0.1-0.2 0.2-0.2 0 0-0.1-0.1 0-0.2 0-0.1 0.1-0.1 0.1-0.1 0.2-0.1 0.4-0.3 0.4-0.4s0.1-0.2 0.1-0.3c0-0.2-0.1-0.4-0.2-0.6-0.3-0.3-0.7-0.3-1.1-0.1-0.3 0.1-0.5 0.5-0.4 0.8 0 0.3 0.2 0.5 0.5 0.6 0.1 0 0.1 0.1 0.1 0.1v0.3h0.1c0.1 0 0.1 0 0.2 0.1l0.1 0.1v0.3c0 0.1 0 0.1-0.1 0.1l0.1 0.1c0.1 0.1 0.1 0.2 0 0.3l-0.2 0.2 0.2 0.2c0.1 0.1 0.1 0.2 0 0.3l-0.3 0.1zm0.1-2.9c-0.2 0-0.4-0.2-0.4-0.4v-0.1c0-0.2 0.1-0.3 0.2-0.4 0.2-0.1 0.4-0.1 0.5 0.1 0.1 0.1 0.2 0.3 0.1 0.5-0.1 0.1-0.2 0.3-0.4 0.3zm0-0.7c-0.1 0-0.1 0 0 0-0.2 0.1-0.2 0.1-0.2 0.2s0.1 0.2 0.2 0.2 0.2-0.1 0.2-0.1v-0.2c-0.1 0-0.2-0.1-0.2-0.1z"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 63.721 -20.336)"
        x="55.4"
        y="65.7"
        width="2"
        height="2"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 67.61 -18.724)"
        x="55.4"
        y="71.2"
        width="2"
        height="2"
      />
      <path
        className="st6"
        d="m71.5 83.2h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z"
      />
      <path
        className="st2"
        d="m118.2 45.4h-27.4c-0.8 0-1.4-0.6-1.4-1.4v-38.6c0-0.8 0.6-1.4 1.4-1.4h27.4c0.8 0 1.4 0.6 1.4 1.4v38.6c0 0.8-0.6 1.4-1.4 1.4z"
      />
      <rect className="st3" x="89.4" y="9.7" width="30.2" height="32.2" />
      <rect className="st4" x="89.4" y="35.7" width="30.2" height="6.2" />
      <rect className="st5" x="90.8" y="5.4" width="4.5" height="2.8" />
      <line className="st6" x1="98" x2="115.8" y1="6.8" y2="6.8" />
      <path d="m115.8 7.8h-17.8c-0.6 0-1-0.4-1-1s0.4-1 1-1h17.8c0.6 0 1 0.4 1 1s-0.5 1-1 1z" />
      <line className="st6" x1="97.8" x2="108.7" y1="17.4" y2="17.4" />
      <path d="m108.7 17.9h-10.9c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h10.9c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="98" x2="113.5" y1="23.2" y2="23.2" />
      <path d="m113.5 23.7h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="98" x2="113.5" y1="28.7" y2="28.7" />
      <path d="m113.5 29.2h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <path className="st7" d="m93.1 19.6v-2.5l-0.1 0.3 0.1 2.2z" />
      <path
        className="st8"
        d="m94 16.2c0-0.2-0.1-0.5-0.3-0.7-0.3-0.3-0.8-0.4-1.2-0.2-0.3 0.3-0.5 0.7-0.5 1s0.3 0.6 0.6 0.7v0.2l-0.1 0.2h0.4v0.5l-0.2 0.1 0.2 0.2v0.1l-0.2 0.2v0.1l0.2 0.2v0.1l-0.1 0.1v0.2l0.3 0.3c0.1 0 0.1-0.1 0.2-0.2v-1.9s0-0.1 0.1-0.1h0.2l-0.2-0.2v-0.2c0.2-0.1 0.4-0.3 0.5-0.5 0.1 0 0.1-0.1 0.1-0.2zm-1.3-0.3c0-0.1 0.1-0.2 0.2-0.3s0.3 0 0.4 0.1 0.1 0.2 0.1 0.3-0.2 0.2-0.3 0.2c-0.2 0-0.4-0.1-0.4-0.3z"
      />
      <path
        className="st9"
        d="m93.1 19.8l-0.4-0.4v-0.3l0.1-0.2-0.2-0.2v-0.2l0.2-0.2-0.3-0.3 0.2-0.2v-0.2h-0.5l0.2-0.4c-0.3-0.1-0.6-0.5-0.6-0.8-0.1-0.4 0.2-0.9 0.6-1 0.4-0.2 1-0.1 1.3 0.2 0.2 0.2 0.3 0.5 0.3 0.7 0 0.1 0 0.2-0.1 0.4s-0.3 0.4-0.5 0.6l0.3 0.4h-0.4v1.9l-0.2 0.2zm-0.2-0.6l0.2 0.2v-1.9c0-0.1 0.1-0.2 0.2-0.2 0 0-0.1-0.1 0-0.2 0-0.1 0.1-0.1 0.1-0.1 0.2-0.1 0.4-0.3 0.4-0.4s0.1-0.2 0.1-0.3c0-0.2-0.1-0.4-0.2-0.6-0.3-0.3-0.7-0.3-1.1-0.1-0.3 0.1-0.5 0.5-0.4 0.8 0 0.3 0.2 0.5 0.5 0.6 0.1 0 0.1 0.1 0.1 0.1v0.3h0.1c0.1 0 0.1 0 0.2 0.1l0.1 0.1v0.3c0 0.1 0 0.1-0.1 0.1l0.1 0.1c0.1 0.1 0.1 0.2 0 0.3l-0.2 0.2 0.2 0.2c0.1 0.1 0.1 0.2 0 0.3l-0.3 0.1zm0.1-2.9c-0.2 0-0.4-0.2-0.4-0.4v-0.1c0-0.2 0.1-0.3 0.2-0.4 0.2-0.1 0.4-0.1 0.5 0.1 0.1 0.1 0.2 0.3 0.1 0.5 0 0.2-0.2 0.3-0.4 0.3zm0-0.6c-0.2 0.1-0.2 0.1-0.2 0.2s0.1 0.2 0.2 0.2 0.2-0.1 0.2-0.1v-0.2c-0.1-0.1-0.1-0.1-0.2-0.1z"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 43.686 -59.079)"
        x="92.1"
        y="22.2"
        width="2"
        height="2"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 47.575 -57.468)"
        x="92.1"
        y="27.7"
        width="2"
        height="2"
      />
      <path
        className="st6"
        d="m108.2 39.7h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z"
      />
      <path
        className="st2"
        d="m118.2 133.2h-27.4c-0.8 0-1.4-0.6-1.4-1.4v-38.6c0-0.8 0.6-1.4 1.4-1.4h27.4c0.8 0 1.4 0.6 1.4 1.4v38.6c0 0.8-0.6 1.4-1.4 1.4z"
      />
      <rect className="st3" x="89.4" y="97.5" width="30.2" height="32.2" />
      <rect className="st4" x="89.4" y="123.5" width="30.2" height="6.2" />
      <rect className="st5" x="90.8" y="93.2" width="4.5" height="2.8" />
      <line className="st6" x1="98" x2="115.8" y1="94.6" y2="94.6" />
      <path d="m115.8 95.6h-17.8c-0.6 0-1-0.4-1-1s0.4-1 1-1h17.8c0.6 0 1 0.4 1 1s-0.5 1-1 1z" />
      <line className="st6" x1="97.8" x2="108.7" y1="105.2" y2="105.2" />
      <path d="m108.7 105.8h-10.9c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h10.9c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="98" x2="113.5" y1="111" y2="111" />
      <path d="m113.5 111.5h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="98" x2="113.5" y1="116.5" y2="116.5" />
      <path d="m113.5 117h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <path className="st7" d="m93.1 107.4v-2.5l-0.1 0.3 0.1 2.2z" />
      <path
        className="st8"
        d="m94 104c0-0.2-0.1-0.5-0.3-0.7-0.3-0.3-0.8-0.4-1.2-0.2-0.3 0.2-0.5 0.6-0.5 0.9s0.3 0.6 0.6 0.7v0.2l-0.1 0.2h0.4v0.5l-0.1 0.1 0.2 0.2v0.1l-0.2 0.2v0.1l0.2 0.2v0.1l-0.1 0.1v0.2l0.3 0.3c0.1 0 0.1-0.1 0.2-0.2v-1.9s0-0.1 0.1-0.1h0.2l-0.2-0.2v-0.2c0.2-0.1 0.4-0.3 0.5-0.5v-0.1zm-1.3-0.3c0-0.1 0.1-0.2 0.2-0.3s0.3 0 0.4 0.1 0.1 0.2 0.1 0.3-0.2 0.2-0.3 0.2c-0.2 0-0.4-0.1-0.4-0.3z"
      />
      <path
        className="st9"
        d="m93.1 107.6l-0.4-0.4v-0.3l0.1-0.2-0.2-0.2v-0.2l0.2-0.2-0.3-0.3 0.2-0.2v-0.2h-0.5l0.2-0.4c-0.3-0.2-0.6-0.5-0.6-0.8-0.1-0.4 0.2-0.9 0.6-1 0.4-0.2 1-0.1 1.3 0.2 0.2 0.2 0.3 0.5 0.3 0.7 0 0.1 0 0.2-0.1 0.4s-0.3 0.4-0.5 0.6l0.3 0.4h-0.4v1.9s0 0.1-0.2 0.2zm-0.2-0.6l0.2 0.2v-1.9c0-0.1 0.1-0.2 0.2-0.2 0 0-0.1-0.1 0-0.2 0-0.1 0.1-0.1 0.1-0.1 0.2-0.1 0.4-0.3 0.4-0.4s0.1-0.2 0.1-0.3c0-0.2-0.1-0.4-0.2-0.6-0.3-0.3-0.7-0.3-1.1-0.1-0.3 0.1-0.5 0.5-0.4 0.8 0 0.3 0.2 0.5 0.5 0.6 0.1 0 0.1 0.1 0.1 0.1v0.3h0.1s0.1 0 0.2 0.1l0.1 0.1v0.3c0 0.1 0 0.1-0.1 0.1l0.1 0.1c0.1 0.1 0.1 0.2 0 0.3l-0.2 0.2 0.2 0.2c0.1 0.1 0.1 0.2 0 0.3l-0.3 0.1zm0.1-2.9c-0.2 0-0.4-0.2-0.4-0.4v-0.1c0-0.2 0.1-0.3 0.2-0.4 0.2-0.1 0.4-0.1 0.5 0.1 0.1 0.1 0.2 0.3 0.1 0.5 0 0.2-0.2 0.3-0.4 0.3zm0-0.6c-0.2 0.1-0.2 0.1-0.2 0.2s0.1 0.2 0.2 0.2 0.2-0.1 0.2-0.1v-0.2c-0.1-0.1-0.1-0.1-0.2-0.1z"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 105.77 -33.361)"
        x="92.1"
        y="110"
        width="2"
        height="2"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 109.66 -31.75)"
        x="92.1"
        y="115.5"
        width="2"
        height="2"
      />
      <path
        className="st6"
        d="m108.2 127.5h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z"
      />
      <path
        className="st2"
        d="m152.9 89h-27.4c-0.8 0-1.4-0.6-1.4-1.4v-38.7c0-0.8 0.6-1.4 1.4-1.4h27.4c0.8 0 1.4 0.6 1.4 1.4v38.6c0 0.9-0.6 1.5-1.4 1.5z"
      />
      <rect className="st3" x="124.1" y="53.2" width="30.2" height="32.2" />
      <rect className="st4" x="124.1" y="79.2" width="30.2" height="6.2" />
      <rect className="st5" x="125.4" y="49" width="4.5" height="2.8" />
      <line className="st6" x1="132.7" x2="150.4" y1="50.4" y2="50.4" />
      <path d="m150.4 51.4h-17.8c-0.6 0-1-0.4-1-1s0.4-1 1-1h17.8c0.6 0 1 0.4 1 1s-0.4 1-1 1z" />
      <line className="st6" x1="132.5" x2="143.4" y1="61" y2="61" />
      <path d="m143.4 61.5h-10.9c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h10.9c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="132.7" x2="148.2" y1="66.8" y2="66.8" />
      <path d="m148.2 67.2h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <line className="st6" x1="132.7" x2="148.2" y1="72.2" y2="72.2" />
      <path d="m148.2 72.8h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z" />
      <path className="st7" d="m127.8 63.1v-2.5l-0.1 0.3 0.1 2.2z" />
      <path
        className="st8"
        d="m128.7 59.8c0-0.2-0.1-0.5-0.3-0.7-0.3-0.3-0.8-0.4-1.2-0.2-0.3 0.2-0.5 0.6-0.5 0.9s0.3 0.6 0.6 0.7v0.2l-0.1 0.2h0.4v0.5l-0.1 0.1 0.2 0.2v0.1l-0.2 0.2v0.1l0.2 0.2v0.1l-0.1 0.1v0.2l0.3 0.3c0.1 0 0.1-0.1 0.2-0.2v-1.9s0-0.1 0.1-0.1h0.2l-0.2-0.2v-0.2c0.2-0.1 0.4-0.3 0.5-0.5v-0.1zm-1.3-0.4c0-0.1 0.1-0.2 0.2-0.3s0.3 0 0.4 0.1 0.1 0.2 0.1 0.3-0.2 0.2-0.3 0.2c-0.2 0-0.4-0.1-0.4-0.3z"
      />
      <path
        className="st9"
        d="m127.8 63.3l-0.4-0.4v-0.3l0.1-0.2-0.2-0.2v-0.2l0.2-0.2-0.3-0.3 0.2-0.2v-0.2h-0.5l0.2-0.4c-0.3-0.2-0.6-0.5-0.6-0.8-0.1-0.4 0.2-0.9 0.6-1 0.4-0.2 1-0.1 1.3 0.2 0.2 0.2 0.3 0.5 0.3 0.7 0 0.1 0 0.2-0.1 0.4s-0.3 0.4-0.5 0.6l0.3 0.4h-0.4v1.9s0 0.1-0.2 0.2zm-0.2-0.5l0.2 0.2v-1.9c0-0.1 0.1-0.2 0.2-0.2 0 0-0.1-0.1 0-0.2 0-0.1 0.1-0.1 0.1-0.1 0.2-0.1 0.4-0.3 0.4-0.4s0.1-0.2 0.1-0.3c0-0.2-0.1-0.4-0.2-0.6-0.3-0.3-0.7-0.3-1.1-0.1-0.3 0.1-0.5 0.5-0.4 0.8 0 0.3 0.2 0.5 0.5 0.6 0.1 0 0.1 0.1 0.1 0.1v0.3h0.1c0.1 0 0.1 0 0.2 0.1l0.1 0.1v0.3c0 0.1 0 0.1-0.1 0.1l0.1 0.1c0.1 0.1 0.1 0.2 0 0.3l-0.2 0.2 0.2 0.2c0.1 0.1 0.1 0.2 0 0.3l-0.3 0.1zm0.1-2.9c-0.2 0-0.4-0.2-0.4-0.4v-0.1c0-0.2 0.1-0.3 0.2-0.4 0.2-0.1 0.4-0.1 0.5 0.1 0.1 0.1 0.2 0.3 0.1 0.5 0 0.1-0.2 0.3-0.4 0.3zm0-0.7c-0.2 0.1-0.2 0.1-0.2 0.2s0.1 0.2 0.2 0.2 0.2-0.1 0.2-0.1v-0.2c-0.1 0-0.1-0.1-0.2-0.1z"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 84.644 -70.849)"
        x="126.8"
        y="65.7"
        width="2"
        height="2"
      />
      <rect
        className="st10"
        transform="matrix(.7071 .7071 -.7071 .7071 88.533 -69.238)"
        x="126.8"
        y="71.2"
        width="2"
        height="2"
      />
      <path
        className="st6"
        d="m142.9 83.2h-15.5c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5h15.5c0.3 0 0.5 0.2 0.5 0.5s-0.2 0.5-0.5 0.5z"
      />
      <polygon className="st11" points="32 104.7 32 107.8 34.9 106.2" />
      <polygon points="67.2 47.5 66.2 47.5 66.2 24.7 89.7 24.7 89.7 25.7 67.2 25.7" />
      <polygon points="89.7 120.5 66.1 120.5 66.1 89 67.1 89 67.1 119.5 89.7 119.5" />
      <polygon points="141.2 47.5 140.2 47.5 140.2 25.9 119.6 25.9 119.6 24.9 141.2 24.9" />
      <polygon points="52.6 67.2 43.2 67.2 43.2 41.8 36.6 41.8 36.6 40.8 44.2 40.8 44.2 66.2 52.6 66.2" />
      <polygon points="45.1 92.4 36.6 92.4 36.6 91.4 44.1 91.4 44.1 83.6 52.6 83.6 52.6 84.6 45.1 84.6" />
      <polygon className="st11" points="77.5 80.8 77.5 83.9 80.5 82.4" />
      <polygon className="st11" points="114.3 125.4 114.3 128.5 117.2 127" />
      <polygon className="st11" points="147.3 81.2 147.3 84.3 150.2 82.7" />
      <polygon className="st11" points="112.8 37.6 112.8 40.7 115.8 39.2" />
      <polygon points="119.7 27.6 119.7 26.5 121.4 25.5 119.7 24.6 119.7 23.4 123.4 25.6" />
      <polygon points="36.6 43.4 36.6 42.3 38.3 41.3 36.6 40.3 36.6 39.1 40.3 41.4" />
      <polygon points="36.6 94 36.6 92.9 38.3 92 36.6 91 36.6 89.8 40.3 92" />
      <polygon points="119.7 122.3 119.7 121.3 121.4 120.3 119.7 119.3 119.7 118.1 123.4 120.4" />
      <polygon points="89.7 118.1 89.6 119.2 88 120.2 89.7 121.1 89.7 122.3 86 120.1" />
      <polygon points="89.7 23.4 89.6 24.4 88 25.4 89.7 26.4 89.7 27.6 86 25.3" />
      <rect x="122.9" y="23.5" width="1" height="3.9" />
      <rect x="138.8" y="45.5" width="3.9" height="1" />
      <rect x="138.8" y="92.8" width="3.9" height="1" />
      <rect x="138.8" y="91.4" width="3.9" height="1" />
      <rect x="64.6" y="92.8" width="3.9" height="1" />
      <rect x="64.6" y="91.4" width="3.9" height="1" />
      <rect x="64.6" y="45.4" width="3.9" height="1" />
      <rect x="64.6" y="44.1" width="3.9" height="1" />
      <rect x="48.9" y="64.8" width="1" height="3.9" />
      <rect x="50.2" y="64.8" width="1" height="3.9" />
      <rect x="48.9" y="82.2" width="1" height="3.9" />
      <rect x="50.2" y="82.2" width="1" height="3.9" />
      <rect x="138.8" y="44.4" width="3.9" height="1" />
      <rect x="39.8" y="39.2" width="1" height="4.6" />
      <rect x="85.5" y="23.1" width="1" height="4.6" />
      <rect x="122.9" y="117.5" width="1" height="3.9" />
      <rect x="85.5" y="117.8" width="1" height="4.6" />
      <rect x="39.8" y="89.6" width="1" height="4.6" />
    </svg>
  );
};

export default Resources;
