import React, { useState, useEffect } from "react";
import "./styles/AboutUs.css"; // Ensure the CSS file is in the same folder
import AboutUsHeader from "../../Images/AboutUs/AboutUs-header.webp";
import Navigation from "../../Utils/Navigation/Navigation";
import ScrollToTop from "../../Utils/ScrollToTop/ScrollToTop";
import Footer from "../../Utils/Footer/Footer";
import FounderImage from "../../Images/AboutUs/profile.webp";

import Loading from "../../Loading";
import { imageList } from "./imageList";

const AboutUs = () => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let loadedImages = 0;

    const loadImage = (url) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        loadedImages++;
        if (loadedImages === imageList.length) {
          setIsLoading(false);
        }
      };
    };

    imageList.forEach(loadImage);
  }, []);

  if (isLoading) {
    return (
      <>
        <Navigation />
        <Loading />
      </>
    );
  }

  return (
    <>
      <Navigation />
      <ScrollToTop />
      <div className="AboutUsHeader">
        <div className="AUTitleIntro">
          <div className="AUtitle">
            <h2>Meet ServeSavvy</h2>
            <p>
              At ServeSavvy, we leverage the latest technology to enhance
              restaurant operations, providing a sophisticated mobile app
              solution designed for both customers and administrators.
            </p>
          </div>
        </div>
        <img src={AboutUsHeader} alt="About Us" className="AUimg" />
      </div>
      <div className="aboutUsContainer">
        <br />
        <h1 className="title">Our Story</h1>

        <p className="OurStoryParagraph">
          Our journey began with a simple partnership: a close friend in the
          restaurant industry faced the challenge of streamlining complex
          operations through technology. Inspired to find a solution, we
          developed a bespoke mobile application designed to enhance customer
          interactions and streamline backend management. This initiative
          quickly demonstrated its value, significantly improving operational
          efficiency and business growth at his restaurant.
          <br />
          <br />
          Witnessing the transformative impact of our solution, we were
          motivated to broaden its reach. We committed to making this innovative
          technology accessible to restaurants throughout the UK. Our mission is
          straightforward: to deliver a robust, yet cost-effective, mobile
          application that simplifies operations, enhances the customer
          experience, and drives business growth.
          <br />
          <br />
          Join us as we continue to pioneer changes in the restaurant industry,
          one app at a time. Together, we aim to craft a seamless dining
          experience that not only delights customers but also propels
          restaurateurs to new heights of success across the nation.
        </p>

        <div className="underLineImg"></div>
      </div>

      <br />
      <section className="section">
        <div className="founderSection">
          <h3 className="heading">Founder: Kevin Alini</h3>
          <p className="paragraph">
            With over 10 years of working and studying experience in software
            engineering and full-stack development, Kevin has a proven track
            record of delivering high-quality solutions that cater to the
            specific needs of businesses. Kevin holds a Master’s Degree in
            Computer Engineering & Informatics, and has extensive expertise in
            JavaScript, Node.js, Python, and React.
            <br />
            <br />
            Before launching ServeSavvy, Kevin led numerous successful projects
            in HbbTV development, test automation, and full-stack application
            development in the London tech industry. His role in advancing
            JavaScript applications for large-screen media devices has been
            instrumental in evolving TV functionality and interactive media
            services.
            <br />
            <br />
            As a leader, Kevin is renowned for his project execution and
            user-focused solutions across Android, iOS, and web platforms. His
            deep knowledge of user interface design, TV user experience, and
            data analysis, along with proficiency in video streaming workflows,
            allows him to create intuitive applications that meet diverse needs
            and improve operational efficiencies.
          </p>
        </div>
        <img src={FounderImage} alt="Founder-Image" className="FounderImg" />
      </section>
      <div className="underLine"></div>
      <div className="AUmissionPromisesTitles">
        <div className="AUmissionPromisesDesc">
          <h3>Elevating Restaurant Industry Excellence</h3>
          <p>
            Learn about our mission to transform how restaurants operate and our
            unwavering promise to our clients. At ServeSavvy, we're dedicated to
            enhancing operational efficiency and customer satisfaction through
            innovative technology solutions. Our tools are designed to
            streamline restaurant management so that you can focus on delivering
            exceptional culinary experiences. Partner with us and experience a
            service that's as reliable as it is revolutionary." This format sets
            a professional tone, highlighting both the mission and promise while
            inviting potential clients to learn more about the benefits of
            partnering with your company.
          </p>
        </div>
        <div className="AUmissionPromises">
          <section className=" missionSection">
            <h3>Our Mission</h3>
            <p>
              At ServeSavvy, our core mission is to empower restaurants of every
              size, enabling them to achieve unparalleled operational efficiency
              and elevate their customer service to new heights. Our robust
              application has undergone rigorous real-world testing to ensure
              reliability under diverse operational demands. It offers a
              comprehensive suite of features designed to meet the unique needs
              of both users and administrators. From seamless automated order
              management systems to in-depth analytics and efficient user
              account management, our solution covers all bases. This allows
              restaurant owners and operators to devote their attention to what
              truly matters—creating memorable dining experiences and serving
              exceptional food. By simplifying the complexities of restaurant
              management, we free you to focus on culinary excellence and
              customer delight.
            </p>
          </section>

          <section className=" promiseSection">
            <h3>Our Promise</h3>
            <p>
              At ServeSavvy, we promise unwavering commitment to innovation,
              reliability, and customer support. We understand the fast-paced,
              ever-evolving nature of the restaurant industry and the critical
              role that cutting-edge technology plays in its success. By
              choosing us, you gain more than just a service provider—you gain a
              partner who is deeply invested in your success and well-versed in
              the dynamics of the restaurant business. We are constantly
              refining our application, drawing on user feedback and the latest
              industry trends to ensure our solutions not only meet but exceed
              your expectations. Our goal is to continually enhance our software
              to help you stay ahead in a competitive market, ensuring that
              every feature we offer contributes to your efficiency and growth.
              Trust us to be with you every step of the way, as you transform
              challenges into opportunities and visions into realities.
              <br /> <br /> This expanded description aims to resonate more
              deeply with prospective clients by detailing the specific benefits
              and commitments your company provides, setting the stage for a
              trusted and successful partnership.
            </p>
          </section>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default AboutUs;
