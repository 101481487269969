import React from "react";
import "./styles/InfoStrip.css"; // Import your CSS file for styling

const InfoStrip = () => {
  return (
    <div className="info-strip">
      <div className="info-block">
        <h3>
          Join the growing number of restaurants optimizing their operations
          with ServeSavvy.
        </h3>
      </div>
      <div className="info-statistics">
        <div className="statistic">
          <h2>#1</h2>
          <p>
            Top Choice for intuitive restaurant management and customer
            engagement solutions.
          </p>
        </div>
        <div className="statistic">
          <h2>99.99%</h2>
          <p>
            Reliability with guaranteed uptime for seamless order processing and
            customer interactions.
          </p>
        </div>
        <div className="statistic">
          <h2>Trusted by Innovators</h2>
          <p>
            Restaurants enhancing their service and profitability with
            ServeSavvy's mobile app solutions.
          </p>
        </div>
        <div className="statistic">
          <h2>Significant ROI</h2>
          <p>
            Unlock value and increase profits by streamlining operations and
            engaging customers more effectively with ServeSavvy.
          </p>
        </div>
      </div>
    </div>
  );
};

export default InfoStrip;
