import React, { useState } from "react";
import "./styles/faqs.css"; // Ensure the CSS file name is correct

function FAQs() {
  const faqData = [
    {
      question: "What are the different subscription tiers available?",
      answer:
        "We offer three subscription tiers: Basic, Premium, and Enterprise, each designed to cater to different needs and scales of operation.",
    },
    {
      question: "How can I upgrade or change my subscription tier?",
      answer:
        "You can upgrade your subscription at any time by contacting our support team for assistance.",
    },
    {
      question: "What payment methods are accepted?",
      answer:
        "We accept various payment methods including credit cards, PayPal, and bank transfers.",
    },
    {
      question:
        "Is there a trial period available before I commit to a subscription?",
      answer:
        "Yes, we offer a free trial for new users to experience our app without any obligations.",
    },
    {
      question: "How do I manage menu items in the app?",
      answer:
        "Admins can manage menu items through the 'Menu Management' section, which includes options for adding, removing, and editing items, depending on the subscribed tier.",
    },
    {
      question: "What analytics are available for admins in the app?",
      answer:
        "The Premium and Enterprise tiers provide analytics on order volume and expenditure, with the Enterprise tier offering advanced graphical analytics.",
    },
    {
      question:
        "How can I handle user accounts that violate our terms of service?",
      answer:
        "For those with the Enterprise tier, full user account management including suspension or deletion is available under the 'User Management' section.",
    },
    {
      question: "How fast can I expect a response from support?",
      answer:
        "Response times vary by subscription tier: within 48 hours for Basic, 24 hours for Premium, and 2 hours for Enterprise, along with priority handling.",
    },
    {
      question:
        "Who can I contact for immediate assistance with system issues?",
      answer:
        "Enterprise tier subscribers have access to a dedicated account manager for immediate assistance, while others can use the general support channels.",
    },
    {
      question:
        "What customization options are available for the app interface?",
      answer:
        "Customization options are exclusive to the Enterprise tier, allowing significant modifications to the app interface and features to align with your branding.",
    },
    {
      question: "How often does the system receive updates?",
      answer:
        "Updates are rolled out monthly for Enterprise subscribers, quarterly for Premium subscribers, and as needed for Basic subscribers.",
    },
  ];

  return (
    <div className="FGAstyle">
      <h2>Frequently asked questions</h2>
      <div className="faq-container">
        {faqData.map((faq, index) => (
          <SingleQuestion key={index} faq={faq} index={index} />
        ))}
      </div>
    </div>
  );
}

const SingleQuestion = ({ faq, index }) => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };
  return (
    <div className={`faq-item ${openFAQ === index ? "open" : ""}`} key={index}>
      <div className="faq-question" onClick={() => toggleFAQ(index)}>
        {faq.question}
        <span className="faq-icon">{openFAQ === index ? "-" : "+"}</span>
      </div>
      {openFAQ === index && <div className="faq-answer">{faq.answer}</div>}
    </div>
  );
};

export default FAQs;
