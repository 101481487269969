import React from "react";
import Navigation from "../../Utils/Navigation/Navigation";
import ScrollToTop from "../../Utils/ScrollToTop/ScrollToTop";
import "./Terms.css"; // Ensure you import the CSS file
import Footer from "../../Utils/Footer/Footer";

function Terms() {
  return (
    <article>
      <Navigation />
      <ScrollToTop />
      <div className="terms">
        <h1>Terms and Conditions</h1>
        <section>
          <h2>1. Introduction</h2>
          <p>
            Welcome to ServeSavvy! These terms and conditions outline the rules
            and regulations for the use of ServeSavvy's Website, located at
            servesavvy.co.uk.
          </p>
          <p>
            By accessing this website we assume you accept these terms and
            conditions. Do not continue to use servesavvy.co.uk if you do not
            agree to take all of the terms and conditions stated on this page.
          </p>
        </section>
        <section>
          <h2>2. Intellectual Property Rights</h2>
          <p>
            Other than the content you own, under these Terms, ServeSavvy and/or
            its licensors own all the intellectual property rights and materials
            contained in this Website. You are granted a limited license only
            for purposes of viewing the material contained on this website.
          </p>
        </section>
        <section>
          <h2>3. Restrictions</h2>
          <p>You are specifically restricted from all of the following:</p>
          <ul>
            <li>Publishing any website material in any other media;</li>
            <li>
              Selling, sublicensing and/or otherwise commercializing any website
              material;
            </li>
            <li>Publicly performing and/or showing any website material;</li>
            <li>
              Using this website in any way that is or may be damaging to this
              website;
            </li>
            <li>
              Using this website in any way that impacts user access to this
              website;
            </li>
            <li>
              Using this website contrary to applicable laws and regulations, or
              in any way may cause harm to the website, or to any person or
              business entity;
            </li>
            <li>
              Engaging in any data mining, data harvesting, data extracting or
              any other similar activity in relation to this website.
            </li>
          </ul>
        </section>
        <section>
          <h2>4. No warranties</h2>
          <p>
            This Website is provided "as is," with all faults, and ServeSavvy
            express no representations or warranties, of any kind related to
            this Website or the materials contained on this website. Also,
            nothing contained on this website shall be interpreted as advising
            you.
          </p>
        </section>
        <section>
          <h2>5. Limitation of liability</h2>
          <p>
            In no event shall ServeSavvy, nor any of its officers, directors and
            employees, be held liable for anything arising out of or in any way
            connected with your use of this website whether such liability is
            under contract. ServeSavvy, including its officers, directors and
            employees shall not be held liable for any indirect, consequential
            or special liability arising out of or in any way related to your
            use of this website.
          </p>
        </section>
        <section>
          <h2>6. Indemnification</h2>
          <p>
            You hereby indemnify to the fullest extent ServeSavvy from and
            against any and/or all liabilities, costs, demands, causes of
            action, damages and expenses arising in any way related to your
            breach of any of the provisions of these terms.
          </p>
        </section>
        <section>
          <h2>7. Severability</h2>
          <p>
            If any provision of these terms is found to be invalid under any
            applicable law, such provisions shall be deleted without affecting
            the remaining provisions herein.
          </p>
        </section>
        <section>
          <h2>8. Variation of Terms</h2>
          <p>
            ServeSavvy is permitted to revise these terms at any time as it sees
            fit, and by using this website you are expected to review these
            terms on a regular basis.
          </p>
        </section>
        <section>
          <h2>9. Assignment</h2>
          <p>
            The ServeSavvy is allowed to assign, transfer, and subcontract its
            rights and/or obligations under these terms without any
            notification. However, you are not allowed to assign, transfer, or
            subcontract any of your rights and/or obligations under these terms.
          </p>
        </section>
        <section>
          <h2>10. Entire Agreement</h2>
          <p>
            These Terms constitute the entire agreement between ServeSavvy and
            you in relation to your use of this website, and supersede all prior
            agreements and understandings.
          </p>
        </section>
        <section>
          <h2>11. Governing Law & Jurisdiction</h2>
          <p>
            These terms will be governed by and interpreted in accordance with
            the laws of the United Kingdom, and you submit to the non-exclusive
            jurisdiction of the state and federal courts located in United
            Kingdom for the resolution of any disputes.
          </p>
        </section>
      </div>
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </article>
  );
}

export default Terms;
