import React from "react";
import { Link } from "react-router-dom";
import errorImage from "./images/error-404-g28f5b5727_1920.webp";
import "./styles/error.css";
export default function ErrorPage() {
  return (
    <article>
      <div className="errorPage">
        <img src={errorImage} alt="errorPage" className="errorImage" />
        <div className="errorInfo">
          <h1>We can't find the page you're looking for.</h1>
          <div>
            {" "}
            <Link
              to="/"
              style={{ fontSize: "1.5vw" }}
              onClick={() => {
                window.scrollTo(0, 0);
              }}
            >
              Return Home
            </Link>
          </div>
        </div>
      </div>
    </article>
  );
}
