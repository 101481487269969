import React, { useEffect, useState } from "react";

const Insights = ({ color = "#000000", width = "200px", height = "200px" }) => {
  const [SVGposition, setSVGposition] = useState({
    a: "0",
    b: "-400",
    c: "3000",
    d: "3000",
  });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 900) {
        setSVGposition({
          a: "0",
          b: "-400",
          c: "3000",
          d: "3000",
        });
      }
      if (window.innerWidth <= 900) {
        setSVGposition({ a: "-1000", b: "-1800", c: "5000", d: "5000" });
      }
      if (window.innerWidth <= 1000) {
        // setSVGposition({ x: "150", y: "150" });
      }
    }

    // Set up the event listener
    window.addEventListener("resize", handleResize);

    // Initial check in case the window is already less than or equal to 100px
    handleResize();

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.0"
      width={width}
      height={height}
      // viewBox="0 -400 3000 3000"
      viewBox={`${SVGposition.a} ${SVGposition.b} ${SVGposition.c} ${SVGposition.d}`}
      preserveAspectRatio="xMidYMid meet"
      id="svg126"
    >
      {" "}
      <metadata id="metadata132"> </metadata> <defs id="defs130" />{" "}
      <g id="layer1" style={{ display: "inline" }} />{" "}
      <g id="layer2" style={{ display: "inline" }}>
        {" "}
        <g
          transform="matrix(0.1,0,0,-0.1,2.025,2020.856)"
          id="g124"
          style={{ fill: "#000000", stroke: "none" }}
        >
          {" "}
          <path
            d="m 14380,17104 c -218,-46 -410,-227 -474,-444 -9,-30 -19,-92 -23,-137 l -6,-83 h 1152 1151 v 68 c 0,84 -20,165 -64,257 -83,175 -243,299 -439,339 -106,22 -1195,22 -1297,0 z"
            id="path4"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 13870,16225 v -155 h -594 c -327,0 -597,-4 -600,-8 -2,-4 0,-59 5,-122 47,-563 271,-1054 658,-1441 142,-141 221,-207 372,-306 536,-351 1198,-459 1824,-298 805,207 1436,842 1641,1651 36,144 62,314 66,439 l 3,80 -532,3 -532,2 -3,153 -3,152 -1152,3 -1153,2 z m 2180,-449 c 0,-274 -4,-495 -10,-526 -35,-184 -201,-358 -382,-400 -26,-6 -279,-10 -633,-10 -492,0 -600,2 -648,15 -143,37 -259,134 -329,273 l -43,87 -3,518 -3,517 h 1026 1025 z"
            id="path6"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 8586,16190 c -35,-11 -66,-38 -78,-69 -5,-14 -9,-353 -9,-815 l 1,-791 30,-3 30,-3 v 789 c 0,763 1,791 19,813 l 19,24 1934,2 1933,3 v 27 l -1,28 -1924,2 c -1059,1 -1938,-3 -1954,-7 z"
            id="path8"
          />{" "}
          <path
            d="m 17565,16190 c -3,-6 -3,-19 0,-30 6,-20 21,-20 1840,-22 l 1834,-3 23,-23 23,-23 3,-919 2,-920 h 30 30 l -2,933 c -3,1020 1,953 -60,995 -21,16 -168,17 -1870,20 -1246,1 -1849,-1 -1853,-8 z"
            id="path10"
          />{" "}
          <path
            d="m 8620,15290 v -780 h 35 35 v 750 750 h 1890 1890 l -4,23 c -3,12 -5,25 -5,30 -1,4 -865,7 -1921,7 H 8620 Z"
            id="path12"
            style={{ fill: "#ff6600" }}
          />{" "}
          <path
            d="m 17569,16063 c -1,-5 -2,-17 -3,-28 -1,-20 7,-20 1794,-25 l 1795,-5 3,-877 2,-878 h 30 30 v 910 910 h -1825 c -1004,0 -1826,-3 -1826,-7 z"
            id="path14"
            style={{ fill: "#ff6600" }}
          />{" "}
          <path
            d="m 20995,14168 c -209,-13 -469,-69 -671,-143 -222,-81 -510,-244 -689,-388 -78,-63 -260,-244 -334,-332 -637,-759 -740,-1876 -254,-2740 246,-436 605,-777 1048,-991 343,-166 700,-248 1080,-247 279,1 575,54 830,147 244,90 534,259 730,426 413,351 697,833 804,1367 158,782 -75,1592 -624,2162 -500,520 -1187,784 -1920,739 z m 475,-84 c 232,-29 534,-121 739,-223 670,-335 1129,-943 1270,-1682 29,-153 36,-241 35,-454 -1,-364 -75,-682 -239,-1020 -115,-237 -246,-418 -454,-626 -191,-190 -367,-319 -589,-429 -621,-309 -1311,-338 -1952,-83 -591,235 -1072,718 -1305,1312 -72,184 -134,437 -152,617 -14,143 -8,469 11,594 38,251 111,482 226,715 119,243 260,434 467,636 163,160 279,246 483,361 440,249 948,347 1460,282 z"
            id="path16"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 21084,13770 c -201,-30 -377,-156 -463,-331 -92,-186 -93,-378 -5,-559 46,-94 169,-220 261,-268 233,-122 521,-85 710,91 80,75 130,152 164,252 70,203 36,408 -95,582 -47,62 -138,138 -206,173 -97,50 -260,76 -366,60 z"
            id="path18"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 20520,12424 c -178,-38 -348,-170 -429,-334 -42,-87 -71,-202 -71,-286 v -44 h 1150 1150 v 68 c 0,281 -215,537 -503,596 -106,22 -1195,22 -1297,0 z"
            id="path20"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 18884,11593 c 42,-702 434,-1372 1027,-1756 684,-444 1536,-496 2264,-137 241,119 432,258 626,456 384,394 603,886 645,1452 l 7,92 h -2288 -2288 z m 3306,-498 c 0,-305 -4,-493 -11,-527 -39,-188 -200,-356 -381,-398 -26,-6 -279,-10 -633,-10 -638,0 -648,1 -754,55 -71,35 -166,128 -205,199 -66,119 -66,117 -66,663 v 493 h 1025 1025 z"
            id="path22"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 8555,14133 c -355,-34 -616,-105 -900,-243 -315,-155 -583,-363 -794,-619 -312,-377 -491,-798 -551,-1296 -16,-131 -13,-420 5,-560 72,-547 299,-1016 675,-1396 161,-163 234,-223 401,-332 333,-218 662,-334 1085,-383 250,-29 571,-6 839,60 438,109 843,342 1153,665 290,303 507,698 606,1102 59,246 85,576 63,794 -61,581 -284,1070 -671,1473 -377,391 -858,635 -1418,718 -99,14 -409,25 -493,17 z m 435,-78 c 83,-9 284,-51 384,-80 692,-201 1253,-701 1519,-1355 68,-166 133,-394 153,-535 3,-27 8,-59 10,-70 16,-87 24,-314 15,-444 -53,-747 -427,-1402 -1032,-1809 -139,-94 -354,-204 -504,-258 -126,-45 -328,-97 -470,-120 -154,-26 -522,-26 -685,-1 -412,65 -805,234 -1123,483 -116,92 -345,329 -431,447 -237,327 -391,710 -441,1102 -19,150 -22,425 -6,560 123,998 820,1789 1785,2026 104,25 242,48 381,63 50,5 371,-2 445,-9 z"
            id="path24"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 8664,13730 c -201,-31 -377,-156 -463,-331 -121,-245 -83,-511 99,-703 118,-123 246,-184 411,-193 188,-11 340,46 470,176 83,84 119,141 154,246 112,333 -86,701 -425,788 -74,19 -184,27 -246,17 z"
            id="path26"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 8100,12384 c -218,-46 -410,-227 -474,-444 -9,-30 -19,-92 -23,-137 l -6,-83 h 1152 1151 v 68 c 0,281 -215,537 -503,596 -106,22 -1195,22 -1297,0 z"
            id="path28"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 6440,11607 c 0,-154 55,-442 122,-635 122,-355 306,-643 580,-907 406,-392 933,-613 1503,-632 905,-30 1728,462 2125,1271 38,78 85,187 104,242 72,211 126,488 126,650 v 64 H 8720 6440 Z m 3330,-552 c 0,-305 -4,-493 -11,-527 -39,-188 -200,-356 -381,-398 -26,-6 -281,-10 -639,-10 -645,0 -640,0 -751,56 -68,35 -164,128 -202,198 -66,119 -66,117 -66,663 v 493 h 1025 1025 z"
            id="path30"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 4575,10553 c -33,-9 -62,-33 -74,-61 -8,-19 -10,-250 -9,-801 l 3,-774 28,-1 27,-2 v 766 c 0,677 2,768 16,788 l 15,22 h 940 939 v 35 35 l -932,-1 c -513,-1 -942,-4 -953,-6 z"
            id="path32"
          />{" "}
          <path
            d="m 11010,10525 v -35 h 899 900 l 15,-22 c 14,-20 16,-112 16,-791 0,-697 1,-769 16,-763 9,3 22,6 30,6 12,0 14,108 12,784 -3,768 -3,784 -23,806 -45,51 -21,50 -975,50 h -890 z"
            id="path34"
          />{" "}
          <path
            d="m 17040,10553 c -39,-12 -63,-30 -76,-55 -21,-41 -21,-1578 0,-1578 8,0 21,-3 30,-6 15,-6 16,66 16,763 0,679 2,771 16,791 l 15,22 h 908 909 l 4,30 c 3,17 2,32 0,35 -7,7 -1802,5 -1822,-2 z"
            id="path36"
          />{" "}
          <path
            d="m 23440,10525 v -35 h 914 915 l 15,-22 c 14,-20 16,-111 16,-788 v -767 l 30,5 30,4 -2,783 c -3,767 -3,783 -23,805 -45,51 -20,50 -990,50 h -905 z"
            id="path38"
          />{" "}
          <path
            d="m 4600,9682 v -769 l 30,5 30,4 v 729 729 h 900 900 v 35 35 h -930 -930 z"
            id="path40"
            style={{ fill: "#ff9900" }}
          />{" "}
          <path
            d="m 11010,10415 v -35 h 860 860 v -730 c 0,-624 2,-730 14,-730 8,0 21,-3 30,-6 15,-6 16,66 16,765 v 771 h -890 -890 z"
            id="path42"
            style={{ fill: "#ff9900" }}
          />{" "}
          <path
            d="m 17060,9682 v -769 l 23,3 c 12,1 25,3 30,3 4,1 7,329 7,731 v 730 l 873,2 872,3 v 30 30 l -902,3 -903,2 z"
            id="path44"
            style={{ fill: "#ff9900" }}
          />{" "}
          <path
            d="m 23440,10415 v -35 h 875 875 l 2,-732 3,-733 28,-2 27,-2 v 770 769 h -905 -905 z"
            id="path46"
            style={{ fill: "#ff9900" }}
          />{" "}
          <path
            d="m 4390,8825 c -304,-43 -541,-158 -745,-359 -116,-115 -171,-188 -239,-317 -197,-376 -203,-801 -16,-1185 71,-146 149,-252 269,-366 106,-101 178,-151 326,-223 127,-63 247,-99 395,-120 132,-18 208,-18 340,0 280,40 531,160 726,347 224,215 353,478 394,808 27,212 -25,498 -127,705 -123,247 -341,465 -588,588 -214,106 -507,155 -735,122 z m 389,-81 c 177,-37 349,-109 481,-202 36,-25 106,-87 156,-136 182,-183 283,-374 340,-647 25,-115 25,-325 1,-439 -60,-278 -157,-461 -341,-646 -145,-145 -268,-223 -452,-287 -160,-56 -250,-71 -414,-71 -253,0 -479,68 -690,206 -96,63 -268,237 -335,340 -162,251 -232,558 -191,837 75,509 418,897 908,1027 168,45 374,52 537,18 z"
            id="path48"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 4463,8606 c -146,-47 -235,-162 -237,-305 -1,-89 25,-158 82,-222 131,-145 349,-148 480,-5 146,159 101,411 -91,506 -75,37 -170,47 -234,26 z"
            id="path50"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 4153,7889 c -67,-26 -146,-100 -174,-163 -12,-28 -25,-76 -28,-108 l -7,-58 h 609 610 l -7,53 c -15,110 -82,209 -178,263 l -53,29 -360,2 c -340,2 -363,1 -412,-18 z"
            id="path52"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 3395,7519 c -10,-15 3,-126 25,-229 48,-224 159,-420 332,-586 191,-183 414,-285 691,-316 227,-25 502,37 702,159 257,157 453,418 524,700 25,98 46,246 38,267 -6,14 -116,16 -1156,16 -812,0 -1152,-3 -1156,-11 z m 1633,-361 c -3,-231 -3,-233 -30,-278 -14,-25 -41,-56 -60,-70 l -33,-25 -347,-3 c -379,-3 -377,-3 -434,56 -50,53 -54,73 -54,322 v 230 h 481 480 z"
            id="path54"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 12799,8830 c -487,-55 -889,-357 -1071,-803 -143,-351 -121,-756 58,-1097 195,-370 548,-614 974,-675 131,-18 196,-18 334,0 278,37 534,158 730,345 374,357 501,912 319,1398 -125,336 -410,623 -745,752 -172,66 -424,100 -599,80 z m 359,-86 c 389,-80 693,-309 862,-649 128,-256 167,-524 115,-780 -104,-511 -463,-871 -975,-981 -115,-24 -343,-25 -457,-1 -247,52 -441,151 -609,309 -156,148 -248,287 -314,476 -60,170 -75,254 -74,427 1,163 17,256 76,424 170,483 666,816 1186,795 62,-2 147,-11 190,-20 z"
            id="path56"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 12803,8606 c -146,-47 -235,-162 -237,-305 -1,-89 25,-158 82,-222 131,-145 349,-148 480,-5 185,201 56,521 -218,541 -41,3 -81,0 -107,-9 z"
            id="path58"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 12492,7889 c -111,-43 -202,-171 -202,-284 v -45 h 606 607 l -7,53 c -15,110 -82,209 -178,263 l -53,29 -360,2 c -342,2 -363,1 -413,-18 z"
            id="path60"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 11773,7515 c -3,-9 0,-59 7,-113 32,-252 138,-477 312,-658 108,-112 189,-172 323,-239 321,-160 679,-164 1010,-11 115,53 214,124 316,225 194,193 303,416 339,694 7,48 9,94 6,102 -5,13 -151,15 -1156,15 -1024,0 -1151,-2 -1157,-15 z m 1595,-357 c -3,-231 -3,-233 -30,-278 -14,-25 -41,-56 -60,-70 l -33,-25 -348,-3 c -343,-3 -348,-2 -387,19 -22,13 -52,40 -67,62 l -28,40 -3,244 -3,243 h 481 481 z"
            id="path62"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 17025,8833 c -304,-28 -589,-163 -805,-382 -188,-190 -301,-402 -357,-671 -24,-117 -24,-363 0,-482 65,-324 239,-602 502,-801 96,-72 286,-166 407,-201 104,-30 271,-56 360,-56 140,0 358,43 488,96 401,163 692,509 785,934 26,119 31,347 10,471 -47,284 -175,531 -377,729 -181,178 -392,288 -651,341 -96,19 -274,30 -362,22 z m 343,-89 c 244,-49 454,-160 625,-329 145,-143 240,-294 301,-477 47,-142 66,-255 66,-398 0,-478 -268,-904 -694,-1106 -346,-163 -727,-161 -1071,6 -119,58 -197,113 -302,213 -115,111 -178,193 -243,319 -205,402 -181,865 65,1245 76,118 251,289 369,362 143,89 345,160 515,181 88,12 275,3 369,-16 z"
            id="path64"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 17033,8606 c -146,-47 -235,-162 -237,-305 -1,-89 25,-158 82,-222 131,-145 349,-148 480,-5 146,159 101,411 -91,506 -75,37 -170,47 -234,26 z"
            id="path66"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 16722,7889 c -111,-43 -202,-171 -202,-284 v -45 h 606 607 l -7,53 c -15,110 -82,209 -178,263 l -53,29 -360,2 c -342,2 -363,1 -413,-18 z"
            id="path68"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 15984,7519 c -11,-18 6,-168 31,-264 94,-367 353,-657 709,-796 528,-205 1132,11 1419,506 80,138 142,343 149,495 l 3,65 -1152,3 c -832,1 -1154,-1 -1159,-9 z m 1614,-361 c -3,-231 -3,-233 -30,-278 -14,-25 -41,-56 -60,-70 l -33,-25 -347,-3 c -379,-3 -377,-3 -434,56 -50,53 -54,73 -54,322 v 230 h 481 480 z"
            id="path70"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 25180,8794 c -314,-40 -566,-155 -774,-354 -119,-114 -197,-220 -267,-365 -159,-330 -179,-669 -58,-1012 149,-422 505,-730 959,-829 120,-27 400,-27 520,0 457,100 821,420 964,847 114,339 92,673 -63,994 -173,359 -505,614 -904,696 -98,20 -302,32 -377,23 z m 355,-89 c 161,-33 324,-100 453,-186 77,-51 215,-180 280,-263 253,-320 325,-755 190,-1151 -168,-496 -630,-825 -1157,-825 -483,0 -908,269 -1111,703 -162,346 -155,738 20,1082 58,114 112,189 209,288 195,201 415,314 706,363 101,17 303,12 410,-11 z"
            id="path72"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 25193,8606 c -146,-47 -235,-162 -237,-305 -1,-89 25,-158 82,-222 131,-145 349,-148 480,-5 146,159 101,411 -91,506 -75,37 -170,47 -234,26 z"
            id="path74"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 24882,7889 c -111,-43 -202,-171 -202,-284 v -45 h 606 607 l -7,53 c -15,110 -82,209 -178,263 l -53,29 -360,2 c -342,2 -363,1 -413,-18 z"
            id="path76"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 24670,7500 c 0,-19 -7,-20 -265,-20 h -265 v -44 c 0,-24 7,-81 15,-128 74,-425 370,-769 780,-906 163,-55 390,-72 555,-43 224,40 437,148 609,307 187,173 332,473 348,722 l 6,92 h -282 c -274,0 -281,0 -281,20 0,20 -7,20 -610,20 -603,0 -610,0 -610,-20 z m 1088,-342 c -3,-231 -3,-233 -30,-278 -14,-25 -41,-56 -60,-70 l -33,-25 -347,-3 c -379,-3 -377,-3 -434,56 -50,53 -54,73 -54,322 v 230 h 481 480 z"
            id="path78"
            style={{ fill: `${color}` }}
          />{" "}
          <path
            d="m 5970,7580 v -30 h 2755 2755 v 30 30 H 8725 5970 Z"
            id="path80"
          />{" "}
          <path
            d="m 18560,7580 v -30 l 2692,2 2691,3 4,28 4,27 h -2696 -2695 z"
            id="path82"
          />{" "}
          <path
            d="m 5970,7470 v -30 h 1185 1185 v -979 c 0,-883 2,-979 16,-985 46,-18 44,-72 44,1009 V 7500 H 7185 5970 Z"
            id="path84"
            style={{ fill: "#b88100" }}
          />{" "}
          <path
            d="m 8470,6491 c 0,-556 3,-1012 8,-1014 4,-3 19,-3 35,0 l 27,5 v 979 979 h 1470 1470 v 30 30 H 9975 8470 Z"
            id="path86"
            style={{ fill: "#b88100" }}
          />{" "}
          <path
            d="m 18560,7470 v -30 h 1370 1370 v -979 -979 l 30,-4 c 17,-3 32,-2 35,0 3,3 5,459 5,1014 v 1008 h -1405 -1405 z"
            id="path88"
            style={{ fill: "#b88100" }}
          />{" "}
          <path
            d="M 21440,6491 V 5482 l 23,-4 c 12,-2 28,-3 35,0 9,3 12,225 12,983 v 979 l 1216,2 1217,3 3,28 3,27 h -1255 -1254 z"
            id="path90"
            style={{ fill: "#b88100" }}
          />{" "}
          <path
            d="m 8250,5304 c -405,-47 -749,-267 -958,-612 -131,-215 -204,-506 -188,-741 24,-346 161,-641 404,-873 198,-188 441,-303 722,-343 137,-19 207,-19 335,0 306,44 540,159 750,370 247,246 378,561 378,910 1,354 -133,677 -384,926 -277,275 -669,409 -1059,363 z m 368,-80 c 247,-45 468,-160 647,-339 175,-175 286,-383 341,-640 23,-110 23,-341 0,-450 -41,-186 -111,-353 -204,-485 -59,-83 -209,-233 -292,-292 -131,-92 -318,-170 -505,-209 -91,-20 -333,-17 -435,5 -166,35 -325,99 -460,187 -84,55 -274,245 -329,329 -86,132 -153,296 -188,460 -26,126 -24,355 5,475 67,272 160,444 337,620 213,213 456,325 780,359 63,6 212,-3 303,-20 z"
            id="path92"
          />{" "}
          <path
            d="m 8313,5126 c -146,-47 -235,-162 -237,-305 -1,-101 25,-164 98,-236 73,-72 129,-95 231,-95 198,0 350,183 314,377 -13,68 -29,103 -76,160 -68,85 -227,133 -330,99 z"
            id="path94"
            style={{ fill: `${color}`, fillOpacity: "0.70739549" }}
          />{" "}
          <path
            d="m 8002,4409 c -111,-43 -202,-171 -202,-284 v -45 h 606 607 l -7,53 c -15,115 -84,214 -188,269 -42,23 -48,23 -403,25 -342,2 -363,1 -413,-18 z"
            id="path96"
            style={{ fill: `${color}`, fillOpacity: "0.70739549" }}
          />{" "}
          <path
            d="m 7790,4020 c 0,-20 -7,-20 -269,-20 -149,0 -272,-3 -274,-7 -3,-5 -1,-48 4,-98 40,-398 294,-757 653,-926 302,-141 661,-145 966,-10 304,133 553,418 639,729 25,90 55,278 47,297 -4,13 -48,15 -276,15 -263,0 -270,0 -270,20 0,20 -7,20 -610,20 -603,0 -610,0 -610,-20 z m 1088,-342 c -3,-231 -3,-233 -30,-278 -14,-25 -41,-56 -60,-70 l -33,-25 -347,-3 c -379,-3 -377,-3 -434,56 -50,53 -54,73 -54,322 v 230 h 481 480 z"
            id="path98"
            style={{ fill: `${color}`, fillOpacity: "0.70739549" }}
          />{" "}
          <path
            d="m 14820,5309 c -36,-4 -107,-18 -159,-30 -597,-137 -1016,-683 -996,-1299 7,-205 55,-388 151,-570 195,-370 548,-614 974,-675 58,-8 134,-15 170,-15 36,0 112,7 170,15 426,61 780,307 974,677 268,511 177,1118 -229,1523 -222,223 -478,342 -804,375 -115,11 -157,11 -251,-1 z m 365,-84 c 181,-38 352,-109 485,-203 83,-59 233,-209 292,-292 142,-202 223,-457 223,-710 0,-466 -259,-888 -671,-1094 -185,-92 -345,-130 -554,-130 -326,0 -610,112 -841,332 -151,144 -244,284 -309,469 -59,170 -74,255 -74,428 1,163 17,256 76,424 169,481 664,815 1184,796 61,-2 146,-11 189,-20 z"
            id="path100"
          />{" "}
          <path
            d="m 14863,5126 c -146,-47 -235,-162 -237,-305 -1,-101 25,-164 98,-236 73,-72 129,-95 231,-95 198,0 350,183 314,377 -13,68 -29,103 -76,160 -68,85 -227,133 -330,99 z"
            id="path102"
            style={{ fill: `${color}`, fillOpacity: "0.70739549" }}
          />{" "}
          <path
            d="m 14552,4409 c -111,-43 -202,-171 -202,-284 v -45 h 606 607 l -7,53 c -15,110 -82,209 -178,263 l -53,29 -360,2 c -342,2 -363,1 -413,-18 z"
            id="path104"
            style={{ fill: `${color}`, fillOpacity: "0.70739549" }}
          />{" "}
          <path
            d="m 14340,4020 c 0,-19 -7,-20 -266,-20 h -267 l 7,-82 c 40,-522 436,-953 957,-1044 126,-22 350,-15 464,15 416,110 723,404 839,805 28,96 53,264 43,290 -6,14 -38,16 -282,16 -268,0 -275,0 -275,20 0,20 -7,20 -610,20 -603,0 -610,0 -610,-20 z m 1088,-342 c -3,-231 -3,-233 -30,-278 -14,-25 -41,-56 -60,-70 l -33,-25 -348,-3 c -343,-3 -348,-2 -387,19 -22,13 -52,40 -67,62 l -28,40 -3,244 -3,243 h 481 481 z"
            id="path106"
            style={{ fill: `${color}`, fillOpacity: "0.70739549" }}
          />{" "}
          <path
            d="m 21240,5306 c -304,-55 -534,-176 -741,-392 -172,-180 -292,-417 -335,-664 -21,-124 -21,-337 -1,-456 79,-447 393,-827 818,-989 206,-79 475,-104 688,-65 469,86 845,403 1000,843 97,276 99,588 4,867 -33,97 -121,266 -180,344 -198,259 -465,430 -783,501 -101,23 -369,29 -470,11 z m 428,-76 c 379,-77 676,-292 857,-619 96,-174 149,-385 149,-591 -1,-344 -138,-661 -386,-896 -483,-455 -1239,-444 -1708,24 -112,112 -170,191 -233,317 -157,314 -175,674 -51,1000 66,172 157,308 301,446 189,182 413,289 693,332 81,13 288,6 378,-13 z"
            id="path108"
          />{" "}
          <path
            d="m 21363,5126 c -146,-47 -235,-162 -237,-305 -1,-89 25,-158 82,-222 131,-145 349,-148 480,-5 185,201 56,521 -218,541 -41,3 -81,0 -107,-9 z"
            id="path110"
            style={{ fill: `${color}`, fillOpacity: "0.70739549" }}
          />{" "}
          <path
            d="m 21052,4409 c -111,-43 -202,-171 -202,-284 v -45 h 606 607 l -7,53 c -15,110 -82,209 -178,263 l -53,29 -360,2 c -342,2 -363,1 -413,-18 z"
            id="path112"
            style={{ fill: `${color}`, fillOpacity: "0.70739549" }}
          />{" "}
          <path
            d="m 20840,4030 c 0,-6 -97,-10 -271,-10 h -272 l 7,-86 c 37,-448 347,-842 780,-990 234,-80 527,-78 759,7 410,149 695,500 757,934 5,39 10,85 10,103 v 32 h -275 c -177,0 -275,4 -275,10 0,7 -210,10 -610,10 -400,0 -610,-3 -610,-10 z m 1088,-352 c -3,-231 -3,-233 -30,-278 -14,-25 -41,-56 -60,-70 l -33,-25 -348,-3 c -343,-3 -348,-2 -387,19 -22,13 -52,40 -67,62 l -28,40 -3,244 -3,243 h 481 481 z"
            id="path114"
            style={{ fill: `${color}`, fillOpacity: "0.70739549" }}
          />{" "}
          <path
            d="m 9786,4103 1,-28 1901,-3 1902,-2 v 30 30 H 11687 9784 Z"
            id="path116"
          />{" "}
          <path
            d="m 16350,4100 v -30 h 1808 1809 v 30 30 h -1809 -1808 z"
            id="path118"
          />{" "}
          <path
            d="m 9784,3993 3,-28 1902,-3 1901,-2 v 30 30 H 11686 9781 Z"
            id="path120"
            style={{ fill: "#ffc022" }}
          />{" "}
          <path
            d="m 16350,3990 v -30 l 1807,2 1806,3 1,28 2,27 h -1808 -1808 z"
            id="path122"
            style={{ fill: "#ffc022" }}
          />{" "}
        </g>{" "}
        <path
          style={{ fill: `${color}`, strokeWidth: 0.1 }}
          d="m 1479.5016,165.9245 c -20.9,1.3 -46.9,6.9 -67.1,14.3 -22.2,8.1 -51,24.4 -68.9,38.8 -7.8,6.3 -26,24.4 -33.4,33.2 -63.7,75.90001 -74,187.60001 -25.4,274.00001 24.6,43.6 60.5,77.7 104.8,99.10001 34.3,16.6 70,24.8 108,24.7 27.9,-0.1 57.5,-5.4 83,-14.7 24.4,-9 53.4,-25.9 73,-42.6 41.3,-35.1 69.7,-83.30001 80.4,-136.70001 15.8,-78.2 -7.5,-159.2 -62.4,-216.20001 -50,-52 -118.7,-78.4 -192,-73.9 z m 47.5,8.4 c 23.2,2.9 53.4,12.1 73.9,22.3 67,33.5 112.9,94.30001 127,168.20001 2.9,15.3 3.6,24.1 3.5,45.4 -0.1,36.4 -7.5,68.2 -23.9,102 -11.5,23.7 -24.6,41.80001 -45.4,62.6 -19.1,19.00001 -36.7,31.90001 -58.9,42.9 -62.1,30.90001 -131.1,33.80001 -195.2,8.30001 -59.1,-23.5 -107.2,-71.80001 -130.5,-131.20001 -7.2,-18.4 -13.4,-43.7 -15.2,-61.7 -1.4,-14.3 -0.8,-46.9 1.1,-59.40001 3.8,-25.09999 11.1,-48.2 22.6,-71.49999 11.9,-24.30001 26,-43.40001 46.7,-63.60001 16.3,-16 27.9,-24.6 48.3,-36.1 44,-24.9 94.8,-34.7 146,-28.2 z"
          id="path16-0"
        />{" "}
        <path
          style={{ fill: `${color}`, strokeWidth: 0.1 }}
          d="m 1489.8256,178.82952 c -20.1,3 -37.7,15.6 -46.3,33.1 -9.2,18.6 -9.3,37.8 -0.5,55.9 4.6,9.4 16.9,22 26.1,26.8 23.3,12.20001 52.1,8.50001 71,-9.09999 8,-7.50001 13,-15.20001 16.4,-25.20001 7,-20.3 3.6,-40.8 -9.5,-58.2 -4.7,-6.2 -13.8,-13.8 -20.6,-17.3 -9.7,-5 -26,-7.6 -36.6,-6 z"
          id="path18-3"
        />{" "}
      </g>{" "}
    </svg>
  );
};

export default Insights;
