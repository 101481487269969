import React, { useEffect, useState } from "react";

const FQAs = ({ color = "#000000", width = "200px", height = "200px" }) => {
  const [SVGposition, setSVGposition] = useState({
    a: 0,
    b: 200,
    c: 720,
    d: 1024,
  });

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > 900) {
        setSVGposition({
          a: 0,
          b: 200,
          c: 720,
          d: 1024,
        });
      }
      if (window.innerWidth <= 900) {
        setSVGposition({ a: 70, b: -50, c: 600, d: 1424 });
      }
      if (window.innerWidth <= 1000) {
        // setSVGposition({ x: "150", y: "150" });
      }
    }

    // Set up the event listener
    window.addEventListener("resize", handleResize);

    // Initial check in case the window is already less than or equal to 100px
    handleResize();

    // Clean up the event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width={width}
      height={height}
      viewBox={`${SVGposition.a} ${SVGposition.b} ${SVGposition.c} ${SVGposition.d}`}
    >
      <g id="layer1">
        <g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M127.87836,736.46124C120.201645,742.5014,110.535194,746.11774,100.03202,746.11774C89.78993,746.11774,80.33592,742.6816,72.75484,736.90704C48.01396,740.9058,29.115646,762.3567,29.115582,788.22797C29.115578,816.9482,52.395462,798.27704,81.11262,798.27704L123.060394,798.27856C151.77925,798.2767,175.06075,816.9478,175.06076,788.2277C175.06076,761.1369,154.33652,738.8917,127.87836,736.46124 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M100.03202,740.06573C121.57512,740.06573,139.09982,722.5379,139.09988,700.993C139.09985,679.4499,121.57516,661.92194,100.032036,661.92194C78.48897,661.9219,60.962547,679.44977,60.96254,700.99286C60.964104,722.53784,78.490524,740.06573,100.03202,740.06573 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M397.1431,410.16394C389.46616,416.20444,379.79968,419.8208,369.2965,419.8208C359.05457,419.8208,349.60056,416.3844,342.0196,410.61008C317.27853,414.6092,298.3804,436.05978,298.3804,461.931C298.3804,490.65158,321.66022,471.98004,350.37726,471.97974L392.3252,471.9813C421.04395,471.97974,444.32535,490.6511,444.32535,461.9306C444.3253,434.83994,423.6013,412.5946,397.1431,410.16394 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M369.2965,413.7688C390.83975,413.76877,408.3646,396.2407,408.3646,374.696C408.3646,353.1527,390.8398,335.62488,369.29663,335.6249C347.7535,335.6249,330.22726,353.153,330.22733,374.69623C330.2289,396.24103,347.75504,413.7688,369.2965,413.7688 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M677.99927,736.4612C670.32263,742.50146,660.65625,746.1176,650.15295,746.1176C639.9108,746.11774,630.4567,742.6815,622.8757,736.907C598.1346,740.9059,579.2366,762.3565,579.2367,788.22766C579.2366,816.9481,602.5163,798.2769,631.23346,798.2769L673.1814,798.2787C701.9002,798.2769,725.1816,816.94806,725.1817,788.2278C725.1816,761.13715,704.45746,738.89166,677.99927,736.4612 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M650.15295,740.0656C671.6959,740.0657,689.2208,722.5378,689.2207,700.99274C689.2208,679.4497,671.6958,661.9217,650.1527,661.92163C628.60944,661.92163,611.08325,679.44977,611.0831,700.99286C611.0849,722.53784,628.61127,740.0657,650.15295,740.0656 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M397.14304,1006.7442C389.4664,1012.7846,379.79974,1016.4007,369.29663,1016.4007C359.05457,1016.4006,349.60043,1012.96454,342.01938,1007.19C317.27853,1011.189,298.38022,1032.6399,298.38022,1058.511C298.38016,1087.2314,321.66013,1068.5603,350.37717,1068.56L392.3252,1068.562C421.04388,1068.5602,444.3253,1087.2316,444.3253,1058.5111C444.3253,1031.4203,423.6013,1009.17474,397.14304,1006.7442 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M369.29663,1010.34894C390.83978,1010.34894,408.3646,992.821,408.3646,971.27606C408.3646,949.7327,390.8398,932.20483,369.29672,932.20483C347.75342,932.2047,330.22705,949.7326,330.22705,971.27606C330.2287,992.82086,347.75504,1010.34894,369.29663,1010.34894 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M648.8959,852.7294L648.8959,852.7311C635.23157,885.69885,615.8054,915.66364,591.87695,941.3736L577.37726,927.8788C599.72144,903.8714,617.8501,875.90216,630.5972,845.14557 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M584.52496,948.99744L584.52496,948.99927C559.67145,973.87225,530.4228,994.36255,498.0354,1009.2043L489.78348,991.1973C520.0015,977.3495,547.30365,958.22614,570.5125,934.9973 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M261.07538,1013.64545L261.07538,1013.64703C228.09366,1000.0094,198.112,980.6077,172.3829,956.7025L185.8653,942.19147C209.88977,964.5128,237.8765,982.6202,268.6434,995.3405 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M164.75255,949.35693L164.75255,949.35864C139.8561,924.52405,119.34149,895.2932,104.47235,862.91956L122.47226,854.65234C136.34492,884.8568,155.49263,912.14374,178.74098,935.3331 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M99.8293,625.9626L99.8293,625.96405C113.437706,592.9681,132.8134,562.97095,156.69576,537.2225L171.21838,550.6927C148.91743,574.7358,130.83557,602.73505,118.14077,633.5151 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M164.03516,529.5859L164.03516,529.5876C188.84683,504.66858,218.06226,484.12793,250.42084,469.23236L258.7036,487.22513C228.51338,501.12292,201.24042,520.29407,178.071,543.5621 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M487.37494,464.3858L487.37494,464.3874C520.3824,477.96643,550.3945,497.31598,576.16266,521.1758L562.705,535.7097C538.64374,513.43036,510.62958,495.37228,479.83817,482.7037 Z" />
          </g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M583.80585,528.50867L583.80585,528.5104C608.74536,553.30023,629.3095,582.49713,644.2332,614.842L626.24725,623.14026C612.3235,592.962,593.13,565.70734,569.8411,542.55664 Z" />
          </g>
        </g>
        <g>
          <g
            style={{
              fill: `${color}`,
              fillOpacity: 1.0,
              strokeLinejoin: "miter",
            }}
          >
            <path d="M380.84528,539.7768Q358.2047,539.7768,338.33652,545.78345Q318.4683,551.32806,303.2207,562.41724Q288.435,573.50653,279.65594,590.1403Q271.3391,606.7741,271.3391,628.9526L343.41904,646.51056Q343.41904,634.4971,352.66006,625.7181Q361.9011,616.9391,375.76266,616.9391Q387.77603,616.939,397.0171,624.3317Q405.79605,631.26245,405.79605,643.2759Q405.79605,651.59283,398.8652,659.9095Q392.39648,668.2263,373.4524,680.24005Q364.21133,685.7848,356.3566,693.1775Q348.96362,700.10803,342.95697,710.2733Q337.41235,719.9761,334.17804,732.91345Q330.9436,745.8511,330.9436,762.9467L330.9436,776.80817L394.70676,776.808Q394.70676,771.7256,395.1688,767.1052Q396.09293,758.3261,398.86514,753.2437Q406.72003,740.7685,415.961,732.45154Q425.202,724.1344,434.44296,717.20374Q443.68405,710.7352,452.46304,704.2665Q461.242,698.25995,468.17273,689.4808Q474.64145,680.7019,478.7998,668.22626Q482.95816,656.2131,482.9582,637.26904Q482.9582,615.0906,475.1035,597.07086Q467.71063,579.51294,453.84918,566.5756Q440.44977,554.1003,421.96774,546.70734Q403.48575,539.77686,381.3073,539.77686 Z M331.40576,819.31714L331.40573,883.0799L394.70676,883.07965L394.7068,819.31696 Z" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default FQAs;
