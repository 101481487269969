import React from "react";
import Navigation from "../../Utils/Navigation/Navigation";
import ScrollToTop from "../../Utils/ScrollToTop/ScrollToTop";
import "./Privacy.css"; // Ensure you import the CSS file
import Footer from "../../Utils/Footer/Footer";

function Privacy() {
  return (
    <article className="PrivacyPage">
      <Navigation />
      <ScrollToTop />
      <div className="privacy">
        <h1>Privacy Policy</h1>
        <section>
          <h2>1. Information Collection and Use</h2>
          <p>
            For a better experience while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to your name, phone number, and postal
            address. The information that we collect will be used to contact or
            identify you.
          </p>
        </section>
        <section>
          <h2>2. Log Data</h2>
          <p>
            We want to inform you that whenever you visit our website, we
            collect information that your browser sends to us that is called Log
            Data. This Log Data may include information such as your computer’s
            Internet Protocol ("IP") address, browser version, pages of our
            website that you visit, the time and date of your visit, the time
            spent on those pages, and other statistics.
          </p>
        </section>
        <section>
          <h2>3. Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. Thus, we advise
            you to review this page periodically for any changes. These changes
            are effective immediately, after they are posted on this page.
          </p>
        </section>
      </div>
      <br />
      <br />
      <br />
      <div className="privacyFooter">
        <Footer />
      </div>
    </article>
  );
}

export default Privacy;
